/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';

const { Option } = Select;

const AddNewShipments = (props) => {

    const {
        showAddModel, setShowAddModel
    } = props;

    const [equipmentId, setEquipmentId] = useState('');
    const [equipTypeForm] = Form.useForm();


    /** Adding or Updating Equipment types */
    const handleOk = () => {

    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setShowAddModel(false)
        //equipTypeForm.resetFields();
    }


    return (
        <div>
            <Modal
                className="pipeline-modal"
                title={'Add New Shipment Data'}
                centered
                visible={showAddModel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk}>
                        Add
                    </Button>
                ]}
            >
                <Form
                    form={equipTypeForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getContainer={false}
                >
                    <Row gutter={24}>
                        <Col lg={12}>
                            <Form.Item
                                label="Shipment Type"
                                name="Shipment Type"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select  Shipment type'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"

                                >

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                label="Shipment Number"
                                name="shipmentNumbe"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select  shipment number'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"

                                >

                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>
        </div>
    );
};



export default AddNewShipments;