/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, notification, Button, Upload
} from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { addSowDetails } from '../../redux/PQTsowDetails/actions';
import { addNewPilelineDetails, UpdateSowDetails } from '../../redux/PQTsowDetails/saga';
import axiosConfig from '../../redux/PQTsowDetails/axiosConfig';
import axios from 'axios';

const { Text } = Typography;
const { TextArea } = Input;
const AddNewPQTPipeline = (props) => {
    const {
        isModalVisible,
        setIsModalVisible,
        editDetailsForm,
        projectId,
        onSave,
        editValue,
        setEditValue,
        SubmittedNewPipeline,
        selectedData
    } = props;

    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [uploadedFilesList, setUploadedFilesList] = useState([]); // Store uploaded files
    const [formData, setFormData] = useState({
        pipelineSection: '',
        projectId: projectId,
        applicationProcedure: '',
        recordDocument: '',
        specification: '',
        documents: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
    });

    useEffect(() => {
        if (editValue) {

            setFormData(selectedData)
        }

    }, [])

    // Handle file upload changes
    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    // Upload props
    const fileProps = {
        multiple: true, // Allow multiple files
        fileList,
        beforeUpload: (file) => {
            setFileList((prev) => [...prev, file]);
            return false; // Prevent automatic upload
        },
        onRemove: (file) => {
            setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
        },
        accept: ".xlsx,.xls,.pdf", // Allow Excel & PDF files
        maxCount: 100, // Allow up to 100 files
    };
    /** Validate Form Data */
    const validateForm = () => {
        let errors = [];

        if (!formData.pipelineSection.trim()) errors.push("Pipeline Section is required.");
        if (!formData.specification.trim()) errors.push("Specification is required.");
        if (!formData.applicationProcedure.trim()) errors.push("Application Procedure is required.");
        if (!formData.recordDocument.trim()) errors.push("Record Document is required.");

        if (errors.length > 0) {
            notification.error({
                message: "Validation Error",
                description: errors.join(" "),
            });
            return false;
        }

        return true;
    };

    // Function to upload files to S3
    const uploadFilesToS3 = async () => {
        try {

            const uploadedFiles = [];

            if (fileList && fileList.length > 0) {
                const fileObjs = fileList.map(file => ({
                    fileName: file.name.replace(/\(\d+\)/g, "").trim(), // Remove (1), (2), etc.
                    contentType: file.type
                }));

                // Get signed URLs for multiple files
                const response = await axiosConfig.post(`/pqt-sow/upload/presigned-url`, { files: fileObjs });
                //  console.log(response)
                if (response.status === 200) {
                    for (const data of response.data.data) {
                        const file = fileList.find(f => f.name.replace(/\(\d+\)/g, "").trim() === data.fileName); // Match sanitized filename
                        //  console.log(data, file)
                        if (file) {
                            try {
                                // Upload file to S3
                                const uploadResponse = await axios.put(data.presignedUrl, file, {
                                    headers: {
                                        "Content-Disposition": "attachment",
                                        "Content-Type": file.type || "application/octet-stream",
                                    },
                                });
                                //  console.log(uploadResponse)

                                if (uploadResponse.status === 200) {
                                    uploadedFiles.push({ s3Url: data.fileUrl, fileName: data.fileName });
                                } else {
                                    console.error(`Error uploading file: ${file.name}`, uploadResponse);
                                }
                            } catch (error) {
                                console.error(`Error processing file: ${file.name}`, error);
                            }
                        }
                    }
                }

                setFileList([]); // Clear selected files after upload
                setUploadedFilesList(uploadedFiles); // Store uploaded URLs
                //console.log("Uploaded Files:", uploadedFiles);
            }
            return uploadedFiles
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    /** Handle Submit */
    const handleOk = async () => {


        if (!validateForm()) return;

        // console.log("Form Data Submitted:", formData);

        // API Call or Save Data
        //onSave(formData);
        setLoading(true)
        if (fileList && fileList.length > 0) {
            let documentData = formData['documents'] || []; // Ensure it's an array

            const createdDoc = await uploadFilesToS3();

            formData['documents'] = [...documentData, ...createdDoc];

        }



        if (editValue) {
            await UpdateSowDetails(formData)
        }
        else
            await addNewPilelineDetails(formData)
        // Reset Form

        setLoading(false)
        setEditValue(false)
        SubmittedNewPipeline()
        setIsModalVisible(false);
    };

    /** Handle Input Change */
    const handleInputChange = (name, value) => {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    /** Handle Cancel */
    const handleCancel = () => {
        setFormData({
            pipelineSection: '',
            projectId: '',
            applicationProcedure: '',
            recordDocument: '',
            specification: '',
            documents: [],
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        });
        setEditValue(false)

        setIsModalVisible(false);
    };

    return (
        <Modal
            width="43vw"
            className="pipeline-modal"
            title="Statement of Work"
            centered
            visible={isModalVisible}
            maskClosable={false}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="apply" type="primary" onClick={handleOk} loading={Loading}>
                    {editValue ? "Update" : "Add"}
                </Button>,
            ]}
        >
            <Form
                form={editDetailsForm}
                name="basic"
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col lg={12}>
                        <Form.Item label="Pipeline Section" required>
                            <Input
                                value={formData.pipelineSection}
                                placeholder="Enter Pipeline Section"
                                onChange={(e) => handleInputChange('pipelineSection', e.target.value)}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item label="Specification" required>
                            <Input
                                value={formData.specification}
                                placeholder="Enter Specification"
                                onChange={(e) => handleInputChange('specification', e.target.value)}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item label="Application Procedure" required>
                            <TextArea
                                value={formData.applicationProcedure}
                                placeholder="Enter Application Procedure"
                                onChange={(e) => handleInputChange('applicationProcedure', e.target.value)}
                                size="large"
                                style={{
                                    height: 100,    // Fixed height
                                    overflowY: "auto",  // Enables scrollbar when needed
                                    resize: "none"  // Prevents manual resizing
                                }}

                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item label="Record Document" required>
                            <TextArea
                                value={formData.recordDocument}
                                placeholder="Enter Record Document"
                                onChange={(e) => handleInputChange('recordDocument', e.target.value)}
                                size="large"
                                style={{
                                    height: 100,    // Fixed height
                                    overflowY: "auto",  // Enables scrollbar when needed
                                    resize: "none"  // Prevents manual resizing
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <div className="uploadFile_admin">
                            <Upload {...fileProps}>
                                <Button icon={<CloudUploadOutlined />} disabled={fileList.length >= 100}>
                                    Select File (Excel / PDF)
                                </Button>
                            </Upload>

                        </div>
                    </Col>
                </Row>
            </Form>

            <Text style={{ letterSpacing: "0.5px" }}>Ex: PQT 01</Text>
            <p style={{ fontSize: "14px", color: "orangered" }}>*PQT weld number will generate as shown in above example.</p>
        </Modal>
    );
};

export default AddNewPQTPipeline;