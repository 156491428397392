/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, notification, Select, Tooltip, Typography, Upload, Row, Col, Table } from 'antd';
import { PlusOutlined, EyeOutlined, MoreOutlined, UploadOutlined, CloudUploadOutlined, ArrowLeftOutlined, EditOutlined, DeleteOutlined, RightCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import AddNewShipmentType from './AddNewShipmentType';
import ShipmentNo from './ShipmentNo';


const { Text } = Typography;
const { Option } = Select;

const ShippingManagement = (props) => {

    const { } = props;


    const [checkOpen, setChecksOpen] = useState(false);
    const [openPage, setOpenPage] = useState('');
    const [showAddModel, setShowAddModel] = useState(false);

    const [openShipmentNoPage, setOpenShipmentNoPage] = useState(false);

    //Shipment Types
    const [equipTypeForm] = Form.useForm();
    const [equipTypeSearchForm] = Form.useForm();

    const [openModal, setOpenModel] = useState(false);
    const [equipTypeList, setEquipTypeList] = useState([
        {
            id: 1,
            Shipment_Types: "Air Freight"
        },
        {
            id: 2,
            Shipment_Types: "Sea Freight"
        },
        {
            id: 3,
            Shipment_Types: "Land Courier"
        },
        {
            id: 4,
            Shipment_Types: "Hand Carry"
        },


    ]);
    const [equipTypeListDrop, setEquipTypeListDrop] = useState(equipTypeList);
    const [clear, setClear] = useState(false);
    const [paginationClear, setPaginationClear] = useState(false);
    const [equipmentType, setEquipmentType] = useState('');
    const [editModelValue, setEditModelValue] = useState('');
    const [editRecord, setEditRecord] = useState('');

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [disableFileUpload, setDisableFileUpload] = useState(false);


    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteEquipId, setDeleteEquipId] = useState('');
    const [equipmentTypeId, setEquipmentTypeId] = useState();
    const [equipmentTypeName, setEquipmentTypeName] = useState();

    const typesListColumns = [
        {
            title: 'Shipment Types',
            dataIndex: 'Shipment_Types',
            key: 'Shipment_Types',
            align: 'left',
            // sorter: (a, b) => {return (a-b)},
            ellipsis: true,
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            width: '150px',
            render: (text, record) => {
                return (
                    <div className='tableIcons'>
                        <Tooltip title='Update' placement='left'>
                            <EditOutlined className='icon-18' style={{ margin: '0 10px', color: '#ef314c' }}
                                onClick={() => setShowAddModel(true)}
                            /></Tooltip>
                        <Tooltip title='Delete' placement='top'>  <DeleteOutlined className='icon-18' style={{ margin: '0 10px', color: '#ef314c' }}

                        /></Tooltip>
                    </div>
                );
            },
            align: 'center'
        },

        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            width: '100px',
            render: (text, record) => {
                return (
                    <div className='tableIcons'>
                        <Tooltip title='Shipments' placement='top'>
                            <RightCircleOutlined className='icon-22'
                                onClick={async () => {
                                    setChecksOpen(true);
                                    setOpenShipmentNoPage(true)
                                    // setOpenPage('equipment');
                                    // equipTypeSearchForm.setFieldsValue({ equipmentType: record.pihEquipmentType });
                                    // setEquipmentTypeName(record.pihEquipmentType);
                                    // setEquipmentTypeId(record.id);
                                    // console.log(record)
                                    // await onGetEquipSno({ pihEquipmentType: record.pihEquipmentType });
                                }} /></Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
    ];







    return (
        <div className='admin'>
            <header style={{ textAlign: 'left', height: '28px' }}>

                <div className="create_equip">

                    <div style={{ display: 'inline-flex', height: '90px' }}>
                        {openShipmentNoPage && <div className='back_equip' style={{ paddingTop: '5px' }}>
                            <Tooltip title={' Back'}> <ArrowLeftOutlined onClick={() => {
                                setOpenShipmentNoPage(false)


                            }} /></Tooltip>
                        </div>
                        }
                        <Form
                            form={equipTypeSearchForm}
                            style={{ width: '300px', paddingLeft: '10px' }}
                        >
                            <Form.Item
                                name="equipmentType"
                                required
                            >
                                <Select
                                    className='client-select search_equip'
                                    placeholder='Select shipment type'
                                    showSearch
                                    // allowClear
                                    optionFilterProp="children"
                                    //  onChange={onSelectEquipType}
                                    //  onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                // disabled={checkOpen}
                                >
                                    {/* {equipTypeListDrop && equipTypeListDrop.map((item, i) =>
                                        <Option key={i} value={item && item.pihEquipmentType}>{item && item.pihEquipmentType}</Option>)} */}
                                </Select>
                            </Form.Item>
                        </Form>
                        <Button className='equipBtn' style={{ marginLeft: '10px', background: checkOpen && '#EF314C', border: checkOpen && '1px solid #EF314C', color: checkOpen && '#ffffff4d' }} disabled={checkOpen}>Search</Button>
                        {clear && <Button className='equipBtn' style={{ marginLeft: '10px', background: checkOpen && '#EF314C', border: checkOpen && '1px solid #EF314C', color: checkOpen && '#ffffff4d' }}>Clear</Button>}
                    </div>
                    <div className="flex-auto create_equipment">
                        <div
                            className="new-spare-data"
                        >
                            <Text onClick={() => { setShowAddModel(true) }} className="ps-3 add-new-spare" >
                                <span className="icon-22">
                                    <svg>
                                        <use xlinkHref="#add" />
                                    </svg>
                                </span>
                                Create New Shipment
                            </Text>


                        </div>
                    </div>
                </div>
            </header>
            <hr />

            {!openShipmentNoPage && <div>
                <Row gutter={24}>
                    <Col span={24} style={{ padding: '0px 12px 0' }}>
                        {/* <Skeleton active loading={adminEquipTypesLoading} > */}
                        <Table
                            className='masterEquipTable_Pagination'
                            columns={typesListColumns}
                            dataSource={[...equipTypeList]}
                            scroll={{ x: '100%', y: '100%' }}
                            //pagination={false}
                            pagination={paginationClear ? false : {
                                //  position: ['bottomRight'],
                                showSizeChanger: true,

                                // defaultPageSize: 20,

                                onChange: async (pageNumber, pageSize) => {

                                }
                            }}
                        //loading={adminEquipTypesLoading}
                        />
                        {/* </Skeleton> */}
                    </Col>
                </Row>
            </div>
            }

            {
                openShipmentNoPage &&
                <ShipmentNo>

                </ShipmentNo>
            }

            <AddNewShipmentType
                showAddModel={showAddModel}
                setShowAddModel={setShowAddModel}
            >

            </AddNewShipmentType>
        </div>
    )
};

const mapStateToProps = ({ }) => {

    return {

    }
}

const mapDispatchToProps = {
    // onGetPihEquipmentTypes: fetchAdminPihEquipTypes,
    // onUploadEquipmentFiles: uploadEquipmentfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingManagement);