/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Divider, List, Typography, Collapse, Empty, Table, Tooltip, Skeleton, Select, Form, notification, Modal, Button, Col, Row } from 'antd';
import {
    EyeOutlined,
    SearchOutlined,
    CloseOutlined,
    CheckOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined, ArrowLeftOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import AddNewShipmentNumber from './AddNewShipmentNumber';
import ShipmentHistory from './ShipmentHistory';
import ShipmentLogs from './ShipmentLogs';


const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const ShipmentNo = (props) => {

    const {

    } = props;



    const [searchOpen, setSearchOpen] = useState(false);
    const [searchSno, setSearchSno] = useState(false);
    const [equipSnoDrop, setEquipSnoDrop] = useState([
        {
            "id": 19156,
            "serialNumber": "AC/800/004",
            "description": "",
            "aliasMaterial": "AC/800/004",
            "aliasEquipment": "AC/800/004",
            "usefulLife": "4 Years  0 Months",
            "onRent": 0,
            "newBookValue": "0",
            "plannedAssetDepreciation": "0",
            "lastRentDate": "42305",
            "returnToStockDate": "43270",
            "captalizedOn": "40634",
            "equipmentTypeId": 1,
            "plantId": 1,
            "assetCompanyId": 1,
            "externalMaterialGroupId": 1,
            "pihEquipmentType": "800 CFM Aftercooler",
            "totalCount": 5
        },
        {
            "id": 19157,
            "serialNumber": "AC/800/002",
            "description": "",
            "aliasMaterial": "AC/800/002",
            "aliasEquipment": "AC/800/002",
            "usefulLife": "4 Years  0 Months",
            "onRent": 0,
            "newBookValue": "0",
            "plannedAssetDepreciation": "0",
            "lastRentDate": "42663",
            "returnToStockDate": "43430",
            "captalizedOn": "40634",
            "equipmentTypeId": 1,
            "plantId": 1,
            "assetCompanyId": 1,
            "externalMaterialGroupId": 1,
            "pihEquipmentType": "800 CFM Aftercooler",
            "totalCount": 5
        },
        {
            "id": 19228,
            "serialNumber": "AC/800/005",
            "description": "",
            "aliasMaterial": "AC/800/005",
            "aliasEquipment": "AC/800/005",
            "usefulLife": "12 Years  0 Months",
            "onRent": 0,
            "newBookValue": "2227.27",
            "plannedAssetDepreciation": "-37.12",
            "lastRentDate": "43469",
            "returnToStockDate": "43892",
            "captalizedOn": "42278",
            "equipmentTypeId": 1,
            "plantId": 1,
            "assetCompanyId": 1,
            "externalMaterialGroupId": 1,
            "pihEquipmentType": "800 CFM Aftercooler",
            "totalCount": 5
        },

    ]);
    const [equipSnoList, setEquipSnoList] = useState([
        {
            "id": 19156,
            "serialNumber": "AC/800/004",
            "description": "",
            "aliasMaterial": "AC/800/004",
            "aliasEquipment": "AC/800/004",
            "usefulLife": "4 Years  0 Months",
            "onRent": 0,
            "newBookValue": "0",
            "plannedAssetDepreciation": "0",
            "lastRentDate": "42305",
            "returnToStockDate": "43270",
            "captalizedOn": "40634",
            "equipmentTypeId": 1,
            "plantId": 1,
            "assetCompanyId": 1,
            "externalMaterialGroupId": 1,
            "pihEquipmentType": "800 CFM Aftercooler",
            "totalCount": 5
        },
        {
            "id": 19157,
            "serialNumber": "AC/800/002",
            "description": "",
            "aliasMaterial": "AC/800/002",
            "aliasEquipment": "AC/800/002",
            "usefulLife": "4 Years  0 Months",
            "onRent": 0,
            "newBookValue": "0",
            "plannedAssetDepreciation": "0",
            "lastRentDate": "42663",
            "returnToStockDate": "43430",
            "captalizedOn": "40634",
            "equipmentTypeId": 1,
            "plantId": 1,
            "assetCompanyId": 1,
            "externalMaterialGroupId": 1,
            "pihEquipmentType": "800 CFM Aftercooler",
            "totalCount": 5
        },
        {
            "id": 19228,
            "serialNumber": "AC/800/005",
            "description": "",
            "aliasMaterial": "AC/800/005",
            "aliasEquipment": "AC/800/005",
            "usefulLife": "12 Years  0 Months",
            "onRent": 0,
            "newBookValue": "2227.27",
            "plannedAssetDepreciation": "-37.12",
            "lastRentDate": "43469",
            "returnToStockDate": "43892",
            "captalizedOn": "42278",
            "equipmentTypeId": 1,
            "plantId": 1,
            "assetCompanyId": 1,
            "externalMaterialGroupId": 1,
            "pihEquipmentType": "800 CFM Aftercooler",
            "totalCount": 5
        },

    ]);
    const [equipSnoSearchForm] = Form.useForm();

    //History View
    const [isHistoryModelVisible, setIsHistoryModelVisible] = useState(false);
    const [equipSnoLog, setEquipSnoLog] = useState([]);
    const [adminIntervalId, setAdminIntervalId] = useState('');
    const [adminMaintenanceId, setAdminMaintenanceId] = useState('');
    const [adminMaintenanceType, setAdminMaintenanceType] = useState('');
    const [intervalListData, setIntervalListData] = useState([]);
    const [activeKey, setActiveKey] = useState('');
    const [clickedTableData, setClickedTableData] = useState(false);

    const [equipmentsHistoryLogsForm] = Form.useForm();
    const [configuredMainIntTypes, setConfiguredMainIntTypes] = useState([]);
    const [historyData, setHistoryData] = useState([]);

    //for adding and updating equipments
    const [serialNoModalOpen, setSerialNoModalOpen] = useState(false);
    const [serialModalText, setSerialModalText] = useState('');
    const [serialNoUpdate, setSerialNoUpdate] = useState('');
    const [serialNoDelete, setSerialNoDelete] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [description, setDescription] = useState('');
    const [editModelValue, setEditModelValue] = useState(false);
    const [editRecord, setEditRecord] = useState('');
    const [disableTypes, setDisableTypes] = useState(false);
    const [pihEquipmentType, setPihEquipmentType] = useState('');

    const [equipSerialNoForm] = Form.useForm();
    const [equipmentId, setEquipmentId] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteEquipId, setDeleteEquipId] = useState('');
    const [historyCurrentPage, setHistoryCurrentPage] = useState(1);


    const [showAddModel, setShowAddModel] = useState(false);
    const [isTableModelVisible, setIsTableModelVisible] = useState(false)

    /** Setting coloumn (headers) for Equipment table Details*/
    const columns = [
        {
            title: 'Performed On',
            dataIndex: 'date',
            key: 'date',
            align: 'left',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Interval',
            dataIndex: 'interval',
            key: 'interval',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Performed By',
            dataIndex: 'performedBy',
            key: 'performedBy',
            align: 'center'
        },
        {
            key: 'action',
            dataIndex: 'action',
            width: '120px',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="View History" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px', color: '#ef314c' }}
                                //   onClick={() => { handleEyeClick(record) }}
                                />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
    ];



    /** Setting Open modal for Adding Equipmnet (serial no. / Asset No)  */
    const addModal = () => {
        setShowAddModel(true)
    }

    /** Setting values and Open modal for Updating Equipments  */
    const editModal = (event) => {
        setShowAddModel(true)
    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setDeleteModal(false);
        setDeleteEquipId('');
    }

    /** Close Modal on close of table details */
    const handleCancelTable = () => {
        setIsTableModelVisible(false);

    }

    /**Open checks table / Modal on view of checks (double click on row disabled)  */
    const handleEyeClick = async (record) => {

        setIsTableModelVisible(true);
        // await onGetCheckIntervals();
    }



    return (
        <div className='equipmentsSno'>
            <div className='search_equipSno'>

                {!searchOpen ?
                    <Tooltip title={'Search Shipment Number'}>
                        <SearchOutlined className='searchIcon' onClick={() => setSearchOpen(true)} />
                    </Tooltip>
                    :
                    <div className='searchDiv'>
                        <Form
                            form={equipSnoSearchForm}
                        >
                            <Form.Item
                                name="equipmentSno"
                                required
                            >
                                <Select
                                    className='client-select search_equip'
                                    placeholder='Search shipment number'
                                    showSearch
                                    optionFilterProp="children"
                                    // onChange={onSelectEquipSno}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {equipSnoDrop && equipSnoDrop.map((item, i) =>
                                        <Option key={i} value={item && item.serialNumber}>{item && item.serialNumber}</Option>)}
                                </Select>
                            </Form.Item>
                        </Form>
                        {searchSno && <Tooltip title={'Search'}>
                            <CheckOutlined className='rightIcon' onClick={onSearchEquipmentSno} />
                        </Tooltip>
                        }

                        <Tooltip title={'Clear Search'}>
                            <CloseOutlined className='closeIcon' onClick={() => {
                                setSearchOpen(false);
                                equipSnoSearchForm.resetFields();
                                // setEquipSnoList(adminEquipTypeSnoData)
                            }} />
                        </Tooltip>
                    </div>
                }
            </div>
            <Divider orientation="left" className='divider' style={{ marginTop: searchOpen ? '4px' : '16px' }}>
                <Tooltip placement='top' title='Add New Shipment Number'>
                    <PlusOutlined className='addNewSerialNo' onClick={addModal} />
                </Tooltip>
                <Text>Shipments</Text>
            </Divider>

            <Skeleton loading={false} active>
                <List
                    className="checksList-equip"
                    size="large"
                    bordered
                    dataSource={equipSnoList}
                    renderItem={item =>
                        <List.Item actions={
                            [<Tooltip key={'view'} title={`View ${item.serialNumber} History`} placement='right' onClick={() => handleEyeClick(item)}>
                                <EyeOutlined className='sno_icon' key={'view'} />
                            </Tooltip>,
                            <EditOutlined className='sno_icon' key={'edit'} onClick={() => editModal(item)} />,
                            <DeleteOutlined className='sno_icon' key={'delete'}
                                onClick={() => {
                                    // setDeleteModal(true);
                                    // setDeleteEquipId(item);
                                }} />

                            ]}>
                            <Text
                            // onClick={() => handleViewHistory(item)}
                            >
                                {item.serialNumber}
                            </Text>
                        </List.Item>
                    }
                // loading={adminEquipTypeSnoLoad}
                />
            </Skeleton>
            {/* <Collapse accordion className="checksList-equip">
                {adminEquipTypeSnoData && adminEquipTypeSnoData.map(item =>
                    <Panel header={item.serialNumber} key={item.id}>
                        <Skeleton loading={false} active>
                            <Table
                                className="h-100 sno-table snoDetails_table"
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                                scroll={{ x: '100%', y: '100%' }}
                            />
                        </Skeleton>
                    </Panel>
                )}
            </Collapse> */}

            <Modal
                className="equipTable-modal"
                title={`Shipment Log`}
                centered
                visible={isTableModelVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancelTable}>
                        Close
                    </Button>,

                ]}
            >
                <ShipmentLogs>

                </ShipmentLogs>
            </Modal>



            <Modal
                className="equipTable-modal"
                title={`Shipment History`}
                centered
                visible={isHistoryModelVisible}
                footer={[
                    <Button key="cancel"
                    
                  //  onClick={handleCancelHistory}
                    >
                        Close
                    </Button>

                ]}
            >
               <ShipmentHistory>

               </ShipmentHistory>
            </Modal>

            {showAddModel && <AddNewShipmentNumber
                showAddModel={showAddModel}
                setShowAddModel={setShowAddModel}
            >
            </AddNewShipmentNumber>}

        </div >
    );
};


const mapStateToProps = ({ }) => {

    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentNo);