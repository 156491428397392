/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Modal, notification, Button, Form, Select, Col, Row, Input, Menu, Upload, Layout, Tooltip, Tabs, List, Table, Spin, Drawer, Checkbox } from 'antd';
import * as XLSX from 'xlsx';
import { ArrowLeftOutlined, ExperimentOutlined, FileDoneOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, CloseOutlined, CheckOutlined, DeleteFilled, DownCircleOutlined, DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import AddNewPQTPipeline from './AddNewPQTPipeline';
import PQTTestPage from './PQTTestPage';
import history from '../../services/history';
import { deleteSowDetails, fetchSowDetails } from '../../redux/PQTsowDetails/actions';
import PQTResultsPage from './PQTResultsPage';
const { Title, Text } = Typography;

const PQTDetailsPage = (props) => {
    const {
        selectedProject, sowDetails, onFetchSowDetails,

        stationList,
        testTypesList,
        setStationList, isSowLoading, onDeleteSowDetails, userAccessList
    } = props

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editValue, setEditValue] = useState(false);
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    const [selectedViewRow, setSelectedViewRow] = useState('')
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [listCount, setListCount] = useState(1);
    const [selectedSOW, setSelectedSOW] = useState(null);
    const [showPQTTestPage, setShowPQTTestPage] = useState(false);
    const [showPQTResultPage, setShowPQTResultPage] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isFileModalVisible, setIsFileModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [loadingBtn, setLoadingBtn] = useState(false);
    useEffect(() => {
        const projId = get(selectedProject, 'id', undefined);
        if (projId) {
            onFetchSowDetails(projId);
        }
    }, [selectedProject]);

    //ITP write access control for normal user
    const isButtonDisabled = () => {
        if (userAccessList.includes('admin'))
            return false;
        else if (projectTeamMembersAccessList[0]?.isGuestUser) {
            return true;
        } else if (projectTeamMembersAccessList[0]?.isNormalUser && !projectTeamMembersAccessList[0]?.tabPermissions?.ITP?.write) {
            return true;
        } else {
            return false;
        }
    }
    const isWriteAccess = isButtonDisabled();

    const [pipelineList, setPipelineList] = useState([

        // {
        //     ID: '1',
        //     Pipeline_Section: 'Mainline-1',
        //     Application_Procedure: "CRCE - Application Procedure Specification - Gas Lift Flowline –Offshore Tie-ins - LE / GTS - PPHSS (CRCE-8915-TE-PR-00002)",
        //     Record_Document: 'CRCE - PQT Report - Gas Lift Flowline – Offshore Tie-ins - LE / GTS - PP HSS(CRC-8915-TE-RP-00002)',
        //     Specification: 'GIS 06-404'
        // }
    ]);
    useEffect(() => {
        setPipelineList(sowDetails);

    }, [sowDetails]);
    const columns = [
        {
            title: 'Pipeline Section',
            dataIndex: 'pipelineSection',
            key: 'pipelineSection',
            align: 'center',
            render: (text) => (
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    {text}
                </div>
            ),
        },

        {
            title: 'Application Procedure',
            dataIndex: 'applicationProcedure',
            key: 'applicationProcedure',
            align: 'left',
            width: '18%'
        },
        {
            title: 'Record Document',
            dataIndex: 'recordDocument',
            key: 'recordDocument',
            align: 'Center',

        },

        {
            title: 'Specification',
            dataIndex: 'specification',
            key: 'specification',
            align: 'Center',

        },



        {
            title: 'Action',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'center',
            render: (text, record) => {
                return (

                    <div>
                        {/* 
                        <Tooltip title="View Parts" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onReadParts(record)}
                                />
                            </span>
                        </Tooltip> */}
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onUpdateClick(record)}

                                />
                            </span>
                        </Tooltip>

                        <Tooltip title="Test">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '10px' }}>
                                <ExperimentOutlined style={{ fontSize: "18px" }}

                                    onClick={() => onShowTesPgaeClick(record)}
                                />
                            </span>
                        </Tooltip>

                        <Tooltip title="Results">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '10px' }}>
                                <FileDoneOutlined style={{ fontSize: "18px" }} onClick={() => onShowResultPageClick(record)} />
                            </span>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '10px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>

                        <Tooltip title="Files">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '10px' }}>
                                <FileDoneOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onUpdateFileClick(record)}

                                />
                            </span>
                        </Tooltip>
                    </div>)
            },

        },
    ];


    const onClickFileView = async (record) => {


        if (record !== '') {
            //setIsFileModalVisible(false)
            setSelectedViewRow(record)
            setShowPDFViewer(true)


        }

    }

    const columnsFile = [
        {
            title: 'File Name',
            dataIndex: 'fileName',
            key: 'fileName',
            align: 'left',
            render: (text) => (
                <div style={{ display: 'flex', alignItems: 'left', height: '100%' }}>
                    {text}
                </div>
            ),
        },


        {
            title: 'Action',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'center',
            render: (text, record) => {
                return (

                    <div>
                        <Tooltip title="View PDF">

                            <Button className='p-0 border-0' size='small' target='_blank'>
                                <FilePdfOutlined className='pdfIconRed' onClick={() => onClickFileView(record)} />
                            </Button>

                        </Tooltip>
                        <Tooltip title="Files">

                            <Button href={record.s3Url} className='p-0 border-0' size='small' target='_blank' style={{ marginLeft: '10px' }}>
                                <DownloadOutlined className='pdfIconRed' 
                                    //onClick={() => onUpdateClick(record)} 

                                /> </Button>
                        </Tooltip>
                    </div>)
            },

        },
    ];

    const onUpdateClick = (data) => {
        // console.log(data)
        if (data) {
            setSelectedData(data)

            setEditValue(true)
            setIsModalVisible(true)
        }

    }
    const onUpdateFileClick = (data) => {
        // console.log(data)
        if (data) {
            setSelectedData(data)

            setIsFileModalVisible(true)
        }

    }

    const onShowTesPgaeClick = async (record) => {
        setSelectedSOW(record)
        setShowPQTTestPage(true)
        // history.push('/PQT-Test-Page')
    }
    const onShowResultPageClick = async (record) => {
        setSelectedSOW(record)
        setShowPQTResultPage(true)
        // history.push('/PQT-Test-Page')
    }
    const UpdatePage = (value) => {

        setShowPQTTestPage(value);
    };

    const SubmittedNewPipeline = () => {
        const projId = get(selectedProject, 'id', undefined);
        if (projId) {
            onFetchSowDetails(projId);
        }
    };


    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };

    const handleOk = async () => {
        await onDeleteSowDetails({ data: selectedData });
        setLoadingBtn(true);
        setTimeout(() => {
            setLoadingBtn(false);
            setIsDeleteModalVisible(false);
            setSelectedData('');
        }, 1000)

    }

    const handleCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('')
    }


    return (


        <div className="d-flex flex-column h-100">
            {showPDFViewer ?
                <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center tab-content-title">
                        <Button
                            type="primary"
                            onClick={() => { setShowPDFViewer(false) }}
                            shape="round"
                            style={{ marginLeft: '20px' }}
                            icon={<ArrowLeftOutlined
                                style={{ fontSize: '16px', verticalAlign: 'middle', marginRight: 8, }}
                            />}
                        >
                            {/* <span style={{ verticalAlign: 'middle' }}>Back</span> */}
                        </Button>
                        <Title className="mb-0" level={4} style={{ paddingLeft: '10px' }}>
                            Viewer
                        </Title>
                    </div>
                    <div className='PQT-pdf-container' style={{ backgroundColor: 'transparent' }} >
                        <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                            <Viewer
                                fileUrl={selectedViewRow.s3Url}
                                plugins={[defaultLayoutPluginInstance]}
                            />
                        </Worker>

                    </div>
                </div> :

                <div>
                    <div>
                        {!showPQTTestPage && !showPQTResultPage && <div >
                            <div className="d-flex flex-column h-100">
                                <div className="flex-auto">
                                    <div className="d-flex mb-3 align-items-center tab-content-title">
                                        <Title className="mb-0" level={4}>
                                            Statement of Work
                                        </Title>

                                        <div className="new-calibration">
                                            <Text onClick={() => setIsModalVisible(true)} className="ps-3 add-new-calibration" style={{}}>
                                                <span className="icon-22" style={{ scale: '20px' }}>
                                                    <svg>
                                                        <use xlinkHref="#add" />
                                                    </svg>
                                                </span>
                                                Create New
                                            </Text>
                                        </div>
                                    </div>

                                    <div className="custom-line" />
                                </div>


                                <div className='addPQTDetails'>
                                    <Table columns={columns}

                                        className="h-100 pipeline-table equipPaginationTable"
                                        scroll={{ y: 'calc(100vh - 320px)' }}
                                        // scroll={{ x: '100%', y: '100%' }}
                                        //scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
                                        rowKey={'ID'}

                                        pagination={
                                            listCount > 10 ? {
                                                position: ['bottomRight'],
                                                showSizeChanger: true,
                                                className: 'fixed-pagination',
                                                total: listCount,
                                                defaultPageSize: 10,
                                                defaultCurrent: 1,
                                                current: currentPageNumber,
                                                pageSize: currentPagesize,
                                                onChange: async (pageNumber, pageSize, sorter) => {
                                                    //console.log(listCount, pageNumber, pageSize)
                                                    setCurrentPageNumber(pageNumber);
                                                    setCurrentPagesize(pageSize);


                                                    var obj = {
                                                        pageSize: pageSize,
                                                        pageNumber: pageNumber,
                                                    // searchKey: searchCriteria

                                                    }


                                                    // await onRecordList(obj)

                                                }
                                            }
                                                : false
                                        }
                                        dataSource={[...pipelineList]}
                                        loading={isSowLoading}

                                    />

                                </div>



                            </div>

                            {
                                isModalVisible && <AddNewPQTPipeline className='details-modal'
                                    setIsModalVisible={setIsModalVisible}
                                    isModalVisible={isModalVisible}
                                    projectId={selectedProject.id}

                                    editValue={editValue}
                                    setEditValue={setEditValue}
                                    SubmittedNewPipeline={SubmittedNewPipeline}
                                    selectedData={selectedData}


                                />
                            }
                        </div >
                        }
                        {showPQTTestPage && !showPQTResultPage &&
                            <div>
                                <PQTTestPage
                                    stationList={stationList}
                                    testTypesList={testTypesList}
                                    setStationList={setStationList}
                                    selectedProject={selectedProject}
                                    showPQTTestPage={showPQTTestPage}
                                    setShowPQTTestPage={setShowPQTTestPage}
                                    UpdatePage={UpdatePage}  // ✅ Ensure it's passed
                                    selectedSOW={selectedSOW}
                                    sowList={pipelineList}
                                />
                            </div >

                        }


                        {showPQTResultPage && !showPQTTestPage &&
                            <div>
                                <PQTResultsPage

                                    selectedProject={selectedProject}
                                    showPQTResultPage={showPQTResultPage}
                                    setShowPQTResultPage={setShowPQTResultPage}
                                    selectedSOW={selectedSOW}
                                    pipelineList={pipelineList}
                                />
                            </div >

                        }
                        <Modal
                            width="45vw"
                            className="pipeline-modal"
                            title="Delete Pipeline Section"
                            centered
                            visible={isDeleteModalVisible}
                            footer={[
                                <Button key="cancel" onClick={handleCancel}>
                                    Cancel
                                </Button>,
                                <Button key="apply" type="primary" onClick={handleOk} loading={loadingBtn}>
                                    Delete
                                </Button>
                            ]}
                        >
                            <div>
                                <Text style={{ fontSize: '16px' }}><p>Deleting statement of work will delete all the corresponding Stations and related information.</p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.pipelineSection}</b> statement of work?</Text>
                            </div>
                        </Modal>
                        <Modal
                            width="50vw"
                            className="pipeline-modal"
                            title="Uploaded Files"
                            centered
                            visible={isFileModalVisible}
                            footer={[
                                <Button key="cancel" onClick={() => { setIsFileModalVisible(false) }}>
                                    Cancel
                                </Button>,

                            ]}
                        >
                            <div>
                                <Table
                                    columns={columnsFile}
                                    dataSource={selectedData.documents || []}
                                    pagination={false}
                                    width={'100%'}
                                    className="h-100 pipeline-table equipPaginationTable log-table logDetails_table"
                                />
                            </div>
                        </Modal>
                    </div>
                </div >
            }
        </div >


    );
}

const mapStateToProps = ({ sowPQTReducer, equip }) => {
    const sowDetails = get(sowPQTReducer, 'sowData', []);
    const isSowLoading = get(sowPQTReducer, 'isSowLoading', []);

    const projectTeamMembersAccessList = get(equip, 'projectTeamMembersAccessList', []);
    return {
        sowDetails,
        projectTeamMembersAccessList,
        isSowLoading
    }
}

const mapDispatchToProps = {
    onFetchSowDetails: fetchSowDetails,
    onDeleteSowDetails: deleteSowDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(PQTDetailsPage);
