/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Image, Input, Row, Select, Skeleton, Table, Tabs, Tooltip, Typography, notification } from 'antd';
import {
    LikeFilled, DislikeFilled, ArrowLeftOutlined, EyeOutlined, MoreOutlined, FileImageFilled, DownloadOutlined, FilePdfOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import axios from 'axios';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const ShipmentLogs = (props) => {

    const {

    } = props;

    const [selectedCustomDateInput, setSelectedCustomDateInput] = useState(moment());
    const [checksPerformedReview, setChecksPerformedReview] = useState('');
    const [imageView, setImageView] = useState(false);
    const [imageProperties, setImageProperties] = useState('');
    const [shipmentTypeList, setShipmentTypeList] = useState(['PSI (Pre-Shipment Inspection)','SGR (Site Goods Received)']);
      const [equipmentLogsForm] = Form.useForm();

    /** Setting coloumns for project Checks details */
    const logsColoumn = [
        {
            title: 'Scheduled Date',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            align: 'center',
            ellipsis: true,
            // render: (text, record) => {
            //     return (
            //         <div>
            //             {moment(record.checkPerformedOn).format('DD MMM YYYY')}
            //         </div>
            //     );
            // },
            // ...getColumnSearchProps('date'),

        },
        {
            title: 'Performed On',
            dataIndex: 'checkPerformedOn',
            key: 'checkPerformedOn',
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.checkPerformedOn).format('DD MMM YYYY')}
                    </div>
                );
            },
            // ...getColumnSearchProps('date'),

        },
        {
            title: 'Performed By',
            dataIndex: 'checkPerformedBy',
            key: 'checkPerformedBy',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
            // ...getColumnSearchProps('performedBy'),
        },
        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            // fixed: 'right',
            width: '120px',
            render: (text, record) => {
                return (
                    <Tooltip title="View History" >
                        <span className="icon-18 mr-2 cursor-pointer edit-hover"  >
                            <EyeOutlined style={{ fontSize: '18px', color: '#ef314c' }}
                              //  onClick={() => { handleViewChecksLog(record) }}
                            />
                        </span>
                    </Tooltip>

                );
            },
            align: 'center'
        },
        {
            title: 'Report',
            dataIndex: 'reportDetails',
            key: 'reportDetails',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        {record && record.reportDetails !== null && JSON.stringify(record.reportDetails) != '{}' ?
                            <Tooltip title="View / Download Report" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <Button  className='p-0 pdfIcon border-0' size='small' target="_blank" rel="noopener noreferrer">
                                        <FilePdfOutlined className='pdfIconRed' />
                                    </Button>
                                </span>
                            </Tooltip>                           
                            :
                            <Tooltip title="Report Not Generated" >
                                <span className="icon-24  cursor-pointer edit-hover"  >
                                    <FilePdfOutlined className='gradepdfIconRed' style={{ cursor: 'not-allowed' }} />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
        },
        {
            title: '',
            dataIndex: 'reportDetails',
            key: 'reportDetails',
            align: 'left',
            render: (text, record) => {
                return (
                    <>
                        {record && record.reportDetails !== null && JSON.stringify(record.reportDetails) != '{}' ?

                            null :
                            <Button size='small' type="primary" shape='round' key='submit'
                              //  onClick={() => { handleReportGen(record) }}
                            >
                                Generate Report
                            </Button>

                        }
                    </>

                );
            },
        }
    ];
    /** Setting coloumns for daily check details */
    const checksColoumn = [
        {
            title: 'Checks',
            dataIndex: 'check',
            key: 'check',
            align: 'left',
            width: '50%',
            ellipsis: {
                showTitle: false
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            title: 'Is Checked',
            dataIndex: 'isChecked',
            key: 'isChecked',
            align: 'center',
            width: '100px',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        <span>
                            {record && record.isCheckedField === 1 &&
                                <>
                                    {(record.isChecked === 1 ? <Tooltip placement="right" title={'Checked'}><LikeFilled className='icon-24 like' /></Tooltip>
                                        :
                                        (record.isChecked === 0 ? <Tooltip placement="right" title={'Not Checked'}><DislikeFilled className='icon-24 unlike' /></Tooltip>
                                            :
                                            (record.isChecked === null && <Tooltip placement="right" title={'Check Not performed'}><LikeFilled className='icon-24 gradeOut' /></Tooltip>
                                            ))
                                    )}
                                </>}
                        </span>
                    </div >
                );
            },
        },
        {
            title: 'Result',
            dataIndex: 'yesOrNoField',
            key: 'yesOrNoField',
            align: 'center',
            width: '40%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        {record && record.yesOrNoField !== 1 && record.inputField !== 1 ?
                            <Text>{"-"}</Text>
                            :
                            <>
                                <span>
                                    {record && record.yesOrNoField === 1 &&
                                        <>
                                            {record.yesOrNoFieldValue !== null ?
                                                <Text>{record.yesOrNoFieldValue}</Text> : <Text>{"-"}</Text>
                                            }
                                        </>}
                                </span>
                                {record && record.inputField === 1 && record.yesOrNoField === 1 &&
                                    <span style={{ margin: '0 10px', borderRight: '1px solid gray' }} />
                                }

                                {record && record.inputField === 1 &&
                                    <span style={{ width: '200px', display: (record.inputFieldValue !== null && (record.inputFieldValue).toString().length > 15) ? 'inline-flex' : 'inline' }}>
                                        {record.inputFieldValue !== null ?
                                            <Tooltip title={record.inputFieldValue} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {record.inputFieldValue}
                                                </Text>
                                            </Tooltip> : <Text>{"-"}</Text>
                                        }
                                    </span>}
                            </>
                        }
                    </div >
                );
            },
        },
        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            // fixed: 'right',
            width: '100px',
            render: (text, record) => {
                return (
                    <>
                        {record && record.checkImageFileKey !== null ?
                            <Tooltip title="View Image" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FileImageFilled className='pictureIcon'
                                        onClick={() => { handleViewCheckImages(record) }}
                                    />
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="No Image Found" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FileImageFilled className='gradeOutPictureIcon' style={{ cursor: 'not-allowed' }}
                                    // onClick={() => { handleViewCheckImages(record) }}
                                    />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
            align: 'center'
        },
    ];

;

    const [weekdates, setWeekdates] = useState('');
    const [interval, setInterval] = useState('DAILY');
    // const [activeKey, setActiveKey] = useState('daily');
    // const [intervalListData, setIntervalListData] = useState([]);

  


    


    return (
        <div>
           
            <div className='equipment_logs'>

                    <>
                        <Form
                            form={equipmentLogsForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                            getContainer={false}
                        >
                            <Row gutter={24} className="">
                                <Col lg={5} >
                                    <Form.Item
                                        name="pihMaintenanceType"
                                        required
                                    >
                                        <Select
                                            className='maintenance-select'
                                            placeholder={'Select Shipment Type'}
                                            showSearch
                                            // allowClear
                                            optionFilterProp="children"
                                          //  onChange={handlePihMainChange}
                                            // mode="multiple"
                                            // style={{
                                            //     height: '42px',
                                            //     position: 'relative',
                                            //     top: '7px',
                                            //     width: '100%'
                                            // }}
                                            size='large'
                                        >
                                            {shipmentTypeList && shipmentTypeList.map((item, i) =>
                                                <Option key={i} value={item}>{item}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col lg={4} ><Button className={dailyActive} onClick={event => handleTabActive('daily')}>Daily</Button></Col>
                    <Col lg={4}><Button className={weeklyActive} onClick={event => handleTabActive('weekly')}>Weekly</Button></Col>
                    <Col lg={4}><Button className={monthlyActive} onClick={event => handleTabActive('monthly')}>Monthly</Button></Col> */}

                            </Row>
                        </Form>
                        
                        <div className="custom-line" />
                        <div style={{ height: 'calc(100vh - 360px)' }}>
                            <Table
                                className='log-table logDetails_table historyTable'
                                columns={logsColoumn}
                               // dataSource={[...checksDataLog]}
                                // pagination={true}
                                scroll={{ x: '100%', y: '100%' }}
                              
                            />
                        </div>
                    </> 
            </div>
        </div>
    );
};


const mapStateToProps = ({ equip }) => {
  
    return {
        
    }
}

const mapDispatchToProps = {
   
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentLogs);