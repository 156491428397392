/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import {
    Typography, Table, Row, Col, Form, Select, Tooltip,
    Button
} from 'antd';
import {
    LikeFilled, DislikeFilled, CloseOutlined, HourglassOutlined, HourglassFilled, ArrowLeftOutlined
} from '@ant-design/icons';
//import FilterLogs from './FilterLogs';
import { fetchCalenderDetails, fetchLogDetails, generateLogReports } from '../../redux/logDetails/action';
import { fetchSowDetails } from '../../redux/PQTsowDetails/actions';
import GenerateReport from './GenerateReport';
import LogCalender from './LogCalender';

const { Title, Text } = Typography;

const PQTResultsPage = (props) => {

    //setting up start date and end date of month for filters and getting log details
    var nowdate = new Date();
    var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
    var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);

    const { onFetchLogDetails,
        logDetails,
        showLogLoader,
        selectedProject,
        onFetchSowDetails,
        sowDetails,
        onGenerateLogReports,
        onFetchCalenderDetails,
        isCalenderLoading,
        calenderData,
        isLogReportsLoading,

        showPQTResultPage,
        setShowPQTResultPage,
        selectedSOW,
        pipelineList
        //projectTeamMembersAccessList,,
        // userAccessList
    } = props;

    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
    const [isReportModalVisible, setIsReportModalVisible] = useState(false);
    const [columnList, setColoumList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [filter, setFilter] = useState(false);
    const [projectIdValue, setProjectIdValue] = useState(false);

    const [pipeline, setPipeline] = useState(null);
    const [selectedPipeline, setSelectedPipeline] = useState(null);
    const [yearData, setYearData] = useState(moment().format('YYYY'));
    const [monthData, setMonthData] = useState(moment().format('MM'));
    const [calenderList, setCalenderList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const [calenderForm] = Form.useForm();
    const [calenderView, setCalenderView] = useState(true);
    const [calenderClass, setCalenderClass] = useState('view');
    const [fileURL, setFileURL] = useState('');
    const isWriteAccess = true;

    // useEffect(() => {
    //     if (fileURL) {
    //         let fileName = fileURL.split('/').pop(); // Gets the last part of the URL
    //         fileName = decodeURIComponent(fileName); // Decodes any encoded characters

    //         let a = document.createElement('a');
    //         a.href = fileURL;
    //         a.download = fileName || "PQT_Log_Report.pdf"; // Use extracted filename, fallback if not available
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         // a.click();
    //         // let link = document.createElement('a')
    //         // link.href = fileURL
    //         // a.download = fileName || "PQT_Log_Report.pdf";
    //         // link.click()
    //     }


    // }, [fileURL])

    // /** Fetching API to get  Location details for generating report */
    // useEffect(() => {
    //     const projId = get(selectedProject, 'id', undefined);
    //     if (projId) {
    //        // onFetchSowDetails(projId);
    //         setLocationData([]);
    //         setPipeline('');
    //         setSelectedPipeline('');
    //         setYearData(moment().format('YYYY'));
    //         setMonthData(moment().format('MM'));
    //         calenderForm.resetFields();
    //         setCalenderList([]);
    //         setDataList([]);
    //         setColoumList([]);
    //         setCalenderView(true);
    //         setCalenderClass('view')

    //     }
    //     setProjectIdValue(projId);
    // }, [selectedProject]);

    // /** Getting Location details for generating report */
    // useEffect(() => {
    //     setLocationData(sowDetails);
    // }, [sowDetails]);

    // /**API call for fetching Log Details */
    // useEffect(() => {
    //     const projectId = get(selectedProject, 'id', undefined);
    //     var obj = {
    //         projectId: projectId,
    //         fromDate: moment(monthStartDay).format('YYYY-MM-DD'),
    //         toDate: moment(monthEndDay).format('YYYY-MM-DD'),
    //     }
    //     console.log(obj)
    //     onFetchLogDetails(obj);
    //     setFilterText('');
    //     setFilter(false);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedProject]);

    /**Get Log Data */
    useEffect(() => {
        let logColoums = [];
        let logDataSource = [];
        if (logDetails) {
            setTotalCount(logDetails.totalCount);
            if (logDetails.columns) {
                logDetails.columns.map((item) => {
                    item.width = 170;
                    item.align = 'center'
                    // item.ellipsis = true
                    item.ellipsis = {
                        showTitle: false
                    }
                    item.render = (address) => (
                        <Tooltip placement="topLeft" title={address}>
                            {address}
                        </Tooltip>
                    ),
                        item.render = (text, record) => {
                            return (
                                <div>

                                    {(text === 'accept' ? <LikeFilled className='icon-24 like' />
                                        :
                                        (text === 'reject' ? <DislikeFilled className='icon-24 unlike' />
                                            :
                                            (text === 'pending' ? <Tooltip title="Pending"><HourglassOutlined className='icon-24 pending' /></Tooltip>
                                                : text)))}
                                </div>
                            );
                        },
                        logColoums.push(item);
                });

                logColoums.forEach((item, i) => {
                    if (item.title === "Weld") {
                        item.title = 'Weld #'
                        item.width = 200;
                        item.align = 'center'
                        item.fixed = 'left'
                        item.ellipsis = {
                            showTitle: false
                        }
                        item.render = (address) => (
                            <Tooltip placement="topLeft" title={address}>
                                {address}
                            </Tooltip>
                        ),
                            logColoums.splice(i, 1);
                        logColoums.unshift(item);
                    }


                    if (item.title === "Repair Status") {
                        item.fixed = 'right'
                        logColoums.splice(i, 1);
                        logColoums.splice(logColoums.length - 1, 0, item);
                    }

                    if (item.title === "Overall Status") {
                        item.fixed = 'right'
                        // logColoums.splice(i, 1);
                        // logColoums.push(item);
                    }
                });
                // Adding S.No to table values
                // logColoums.unshift({
                //     "title": "S.No",
                //     "dataIndex": "count",
                //     "key": "count",
                //     width: 60,
                //     align: 'center',
                //     ellipsis: true,
                //     fixed: 'left'
                // });

                logColoums.forEach((item, i) => {

                    if (item.title) {
                        item.ellipsis = {
                            showTitle: false
                        }
                        item.title = <Tooltip title={item.title}>{item.title}</Tooltip>
                    }
                });

                logColoums.forEach((item, i) => {
                    if (item.title) {
                        item.ellipsis = {
                            showTitle: false
                        }
                        item.title = <Tooltip title={item.title}>{item.title}</Tooltip>
                    }
                })
                setColoumList(logColoums);
            }

            if (logDetails.dataSource) {
                var sizeCount = JSON.parse(sessionStorage.getItem('page'));
                logDetails.dataSource.map((item, i) => {
                    if (sizeCount.pageIndex > 0) {
                        item.count = i + 1 + (sizeCount.pageSize * sizeCount.pageIndex)
                    } else {
                        item.count = i + 1
                    }

                    logDataSource.push(item);
                });
                setDataList(logDataSource);
            }
        }
    }, [logDetails]);


    useEffect(() => {
        if (calenderData) {
            setCalenderList(calenderData);
        }
    }, [calenderData])

    /** Function call for getting data on applying filter */
    const onFilter = (fromDate, toDate) => {
        const projectId = get(selectedProject, 'id', undefined);
        var obj = {
            projectId,
            fromDate,
            toDate
        }
        onFetchLogDetails(obj);
        setFilterText(`${moment(fromDate).format("DD/MM/YYYY")} - ${moment(toDate).format("DD/MM/YYYY")}`);
        setFilter(true);
    }

    /** Fucntion call to get data after removing filter */
    const handleLogData = () => {
        const projectId = get(selectedProject, 'id', undefined);
        var obj = {
            projectId: projectId,
            fromDate: moment(monthStartDay).format('YYYY-MM-DD'),
            toDate: moment(monthEndDay).format('YYYY-MM-DD'),
        }
        onFetchLogDetails(obj);
    }

    /** Function call for getting data on applying filter */
    const onGenerateReport = async (data) => {
        await onGenerateLogReports(data)
    }

    const getCalData = async (data) => {
        await onFetchCalenderDetails(data);
    }

    return (
        <div className="d-flex h-100 log-details">
            <div className="d-flex flex-column h-100">
                <div className="d-flex flex-column h-100">
                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">
                            <Button
                                type="primary"
                                onClick={() => { setShowPQTResultPage(false) }}
                                shape="round"
                                style={{ marginLeft: '20px' }}
                                icon={<ArrowLeftOutlined
                                    style={{ fontSize: '16px', verticalAlign: 'middle', marginRight: 8, }}
                                />}
                            >
                                {/* <span style={{ verticalAlign: 'middle' }}>Back</span> */}
                            </Button>
                            <Title className="mb-0" level={4} style={{ paddingLeft: '10px' }}>
                                Pipeline: {selectedSOW.pipelineSection}
                            </Title>

                            <div className='inspection-logs'>

                                <GenerateReport
                                    isReportModalVisible={isReportModalVisible}
                                    setIsReportModalVisible={setIsReportModalVisible}
                                    setLocationData={setLocationData}
                                    locationData={locationData}
                                    projectIdValue={projectIdValue}
                                    onGenerateReport={onGenerateReport}
                                    isLogReportsLoading={isLogReportsLoading}
                                    isWriteAccess={isWriteAccess}
                                    selectedSOW={selectedSOW}
                                    selectedProject={selectedProject}
                                    pipelineList={pipelineList}
                                    setFileURL={setFileURL}
                                />

                            </div>
                        </div>
                        <div className="custom-line" />
                    </div>
                    <div

                    >
                        {/* {dataList.length > 0 ?
                            <Table
                                columns={columnList}
                                dataSource={dataList}
                                // filtered={true}
                                loading={showLogLoader}

                                pagination={false}
                                scroll={{ x: '100%', y: '100%' }}
                            /> :
                            <Table
                                loading={showLogLoader}
                            />} */}

                        <LogCalender
                            locationData={locationData}
                            dataList={dataList}
                            setDataList={setDataList}
                            showLogLoader={showLogLoader}
                            columnList={columnList}
                            totalCount={totalCount}

                            setCalenderView={setCalenderView}
                            calenderView={calenderView}
                            calenderClass={calenderClass}
                            setCalenderClass={setCalenderClass}

                            pipeline={pipeline}
                            setPipeline={setPipeline}
                            selectedPipeline={selectedPipeline}
                            setSelectedPipeline={setSelectedPipeline}
                            yearData={yearData}
                            setYearData={setYearData}
                            monthData={monthData}
                            setMonthData={setMonthData}
                            calenderForm={calenderForm}
                            getCalData={getCalData}
                            calenderList={calenderList}
                            isCalenderLoading={isCalenderLoading}

                            onFetchLogDetails={onFetchLogDetails}
                            isWriteAccess={isWriteAccess}
                            selectedSOW={selectedSOW}

                        />


                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ log, sow }) => {
    const logDetails = get(log, 'logData', []);
    const reportsData = get(log, 'reportsData', []);
    const showLogLoader = get(log, 'isLogDetailsLoading', false);
    const sowDetails = get(sow, 'sowData', []);
    const isCalenderLoading = get(log, 'isCalenderLoading', []);
    const calenderData = get(log, 'calenderData', []);
    const isLogReportsLoading = get(log, 'isLogReportsLoading', []);
    return {
        logDetails,
        showLogLoader,
        sowDetails,
        reportsData,
        isCalenderLoading,
        calenderData,
        isLogReportsLoading
    }
}

const mapDispatchToProps = {
    onFetchLogDetails: fetchLogDetails,
    onFetchSowDetails: fetchSowDetails,
    onGenerateLogReports: generateLogReports,
    onFetchCalenderDetails: fetchCalenderDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(PQTResultsPage);