import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
  fetchSowDetailsSuccess,
  fetchSowDetailsFailure,
  setUpdateSowDetails,
  setDeleteSowDetails,
  addSowDetailsSuccess,
  addSowDetailsFailure,
  setUpdateSowDetailsFailure
} from './actions';
import {
  FETCH_SOW_DETAILS,
  UPDATE_SOW_DETAILS,
  DELETE_SOW_DETAILS,
  ADD_SOW_DETAILS
} from './types';
import axiosConfig from './axiosConfig';

function* fetchSowDetails({ id }) {
  try {
    const res = yield axiosConfig.get(`pqt-sows?projectId=${id}`);
    const response = get(res, ['data', 'data'], {});
    // notification.destroy();
    if (res.status === 200) {
      yield put(fetchSowDetailsSuccess({ response }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchSowDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the project details.',
      description: `${error}`,
    });
  }
}

/** Function to update Pipeline Section (Statement of Work) */
function* updateSowDetails({
  data
}) {
  try {
    const res = yield axiosConfig.put(`/sow/${data.id}`, data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(setUpdateSowDetails({ response }));
      notification.success({
        message: 'Pipeline Data Updated Successfully.',
      });
    } else {
      yield put(setUpdateSowDetailsFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(setUpdateSowDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'Pipeline Updation Failed.',
      description: `${error}`,
    });
  }
}

function* addSowDetails({ payload }) {
  try {
    const res = yield axiosConfig.post(`/pqt-sow`, payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(addSowDetailsSuccess({ response }));
      notification.success({
        message: 'Pipeline Data Added Successfully.',
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(addSowDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while adding the details.',
      description: `${error.error}`,
    });
  }
}

/** Function to delete Pipeline Section (Statement of Work) */
function* deleteSowDetails({
  data
}) {
  try {
    const res = yield axiosConfig.delete(`/pqt-sow/${data.id}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(setDeleteSowDetails({ data }));
      notification.success({
        message: 'Pipeline Data Deleted Successfully.',
      });
    } else {
      notification.error({
        message: 'Something went wrong. Please try again later.',
      });
    }
  } catch (error) {
    yield put(addSowDetailsFailure());
    notification.destroy();
    notification.warn({
      message: 'A problem occured while deleting the details.',
      description: `${error.error}`,
    });

  }
}

function* sowWatcher() {
  yield takeLatest(FETCH_SOW_DETAILS, fetchSowDetails);
  yield takeLatest(UPDATE_SOW_DETAILS, updateSowDetails);
  yield takeLatest(DELETE_SOW_DETAILS, deleteSowDetails);
  yield takeLatest(ADD_SOW_DETAILS, addSowDetails);
}

function* sowPQtSagas() {
  yield all([
    call(sowWatcher),
  ]);
}

export default sowPQtSagas;



export const addNewPilelineDetails = async ( payload)=> {
  try {
    console.log(payload)
    const res = await axiosConfig.post(`/pqt-sow`, payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
     
      notification.success({
        message: 'Pipeline Data Added Successfully.',
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    
    notification.destroy();
    notification.error({
      message: 'A problem occured while adding the details.',
      description: `${error.error}`,
    });
  }
}

export const UpdateSowDetails = async(
  data
) =>{
  try {
    const res = await axiosConfig.put(`/pqt-sow/${data.id}`, data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      await put(setUpdateSowDetails({ response }));
      notification.success({
        message: 'Pipeline Data Updated Successfully.',
      });
    } else {
      await put(setUpdateSowDetailsFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    await put(setUpdateSowDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'Pipeline Updation Failed.',
      description: `${error}`,
    });
  }
}
