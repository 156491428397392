import get from 'lodash/get';
import {
  FETCH_SOW_DETAILS,
  FETCH_SOW_DETAILS_SUCCESS,
  FETCH_SOW_DETAILS_FAILURE,
  UPDATE_SOW_DETAILS,
  SET_SOW_DETAILS_UPDATE,
  DELETE_SOW_DETAILS,
  SET_SOW_DETAILS_DELETE,
  ADD_SOW_DETAILS,
  ADD_SOW_DETAILS_SUCCESS,
  ADD_SOW_DETAILS_FAILURE,
  UPDATE_SOW_DETAILS_FAILURE
} from './types';

const initialState = {
  isSowLoading: false,
};

const sowPQTReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOW_DETAILS: {
      return {
        ...state,
        isSowLoading: true
      }
    }

    case FETCH_SOW_DETAILS_SUCCESS: {
      const resData = get(action, 'response', []);
      resData.sort(function (a, b) {
        return b.id - a.id;
      });
      resData.map((item, i) => {
        item.sno = i + 1
      })
      return {
        ...state,
        sowData: resData,
        isSowLoading: false
      }
    }

    case FETCH_SOW_DETAILS_FAILURE: {
      return {
        ...state,
        isSowLoading: false
      }
    }

    case UPDATE_SOW_DETAILS: {
      return {
        ...state,
        isSowLoading: true
      };
    }

    case UPDATE_SOW_DETAILS_FAILURE: {
      return {
        ...state,
        isSowLoading: false
      };
    }

    case SET_SOW_DETAILS_UPDATE: {
      const sowArr = get(action, 'response', {});
      const sowData = get(state, 'sowData', []);

      var objIndex = sowData.findIndex((obj => obj.id === sowArr.id));
      //Update object's property.
      sowData[objIndex] = sowArr
      const newData = [...sowData];
      return {
        ...state,
        sowData: newData,
        isSowLoading: false
      }
    }

    case ADD_SOW_DETAILS: {
      return {
        ...state,
        isSowLoading: true
      }
    }

    case ADD_SOW_DETAILS_SUCCESS: {
      const resData = get(action, 'response', []);
      const sowData = get(state, 'sowData', []);
      const newData = [...sowData, resData];
      return {
        ...state,
        sowData: newData,
        isSowLoading: false
      }
    }

    case ADD_SOW_DETAILS_FAILURE: {
      return {
        ...state,
        isSowLoading: false
      }
    }

    case DELETE_SOW_DETAILS: {
      return {
        ...state,
        isSowLoading: true
      };
    }

    case SET_SOW_DETAILS_DELETE: {
      const sowArr = get(action, 'data', {});
      const sowData = get(state, 'sowData', []);

      var objIndex = sowData.findIndex((obj => obj.id === sowArr.id));
      //Delete object's property.
      sowData.splice(objIndex, 1);

      const newData = [...sowData];
      return {
        ...state,
        sowData: newData,
        isSowLoading: false
      }
    }

    default: {
      return state;
    }
  }
};

export default sowPQTReducer;
