/* eslint-disable linebreak-style */
import { all } from 'redux-saga/effects';
import projectSagas from './project';
import clientSagas from './client';
import sowSagas from '../redux/sowDetails/saga';
import stationSagas from '../redux/stationDetails/saga';
import testSagas from '../redux/testDetails/saga';
import logSagas from '../redux/logDetails/saga';
import auth from './auth'
import equipmentSagas from '../redux/pihEquipmentDetails/saga';
import maintenanceSagas from '../redux/maintenance/saga';
import materialSagas from '../redux/materialDescription/saga';
import adminMaintenanceSagas from '../redux/admin/maintenance/saga';
import checkIntervalsSagas from '../redux/admin/intervals/saga';
import productHierarchySagas from '../redux/admin/productHierarchy/saga';
import adminEquipTypeSagas from '../redux/admin/equipmentTypes/saga';
import rentalGroupSagas from '../redux/admin/rentalGroups/saga';
import adminEquipSnoSagas from '../redux/admin/equipmentSno/saga';
import adminEquipChecksSagas from '../redux/admin/checks/saga';
import historySagas from '../redux/admin/checksHistory/saga'
import externalMaterialGroupSagas from '../redux/admin/externalMaterialGroup/saga'
import assetCompanySagas from '../redux/admin/assetCompany/saga'
import plantSagas from '../redux/admin/plants/saga'
import uploadSagas from '../redux/admin/upload/saga'
import roleSagas from '../redux/roles/saga'
import categorySagas from '../redux/admin/category/saga'
import materialConsumptionSagas from '../redux/materialConsumption/saga';
import consumablesSagas from '../redux/consumable/saga';
import dsrSagas from '../redux/dsr/saga';
import equipmentUsageSagas from '../redux/equipmentUsage/saga';
import technicianLog from '../redux/technicianLog/saga';
import productionJointsSagas from '../redux/productionJoints/saga';
import downTimeSagas from '../redux/downTime/saga';
import ehsIssuesSagas from '../redux/ehsIssues/saga';
import toolBoxSagas from '../redux/toolbox/saga';
import toolBoxAdminSagas from '../redux/toolboxAdmin/saga';
import crcetechincalsagas from '../redux/CRCETechnical/saga';
import spareAdminSagas from '../redux/admin/sparesAdmin/saga';
import spareDashBoardSagas from '../redux/sparesDashboard/saga';
import reportEmailSagas from '../redux/reportEmail/saga';
import DCALSagas from '../redux/DCAL/saga';
import billingDashBoardSagas from '../redux/billing/saga';
import employeeSagas from '../redux/admin/employees/saga';
import calibrationSagas from '../redux/calibration/saga';
import sowPQtSagas from '../redux/PQTsowDetails/saga';
import stationPQTSagas from '../redux/PQTstation/saga';
import testPQTSagas from '../redux/PQTtestDetails/saga';

import HeatProfileSagas from '../redux/heatProfile/saga'
export default function* rootSaga() {
  yield all([
    projectSagas(),
    clientSagas(),
    sowSagas(),
    stationSagas(),
    testSagas(),
    logSagas(),
    auth(),
    equipmentSagas(),
    maintenanceSagas(),
    materialSagas(),
    adminMaintenanceSagas(),
    checkIntervalsSagas(),
    productHierarchySagas(),
    adminEquipTypeSagas(),
    rentalGroupSagas(),
    adminEquipSnoSagas(),
    adminEquipChecksSagas(),
    historySagas(),
    externalMaterialGroupSagas(),
    assetCompanySagas(),
    plantSagas(),
    uploadSagas(),
    roleSagas(),
    categorySagas(),
    materialConsumptionSagas(),
    consumablesSagas(),
    dsrSagas(),
    equipmentUsageSagas(),
    technicianLog(),
    productionJointsSagas(),
    downTimeSagas(),
    ehsIssuesSagas(),
    toolBoxSagas(),
    toolBoxAdminSagas(),
    crcetechincalsagas(),
    spareAdminSagas(),
    spareDashBoardSagas(),
    reportEmailSagas(),
    billingDashBoardSagas(),
    DCALSagas(),
    HeatProfileSagas(),,
    employeeSagas(),
    calibrationSagas(),
    sowPQtSagas(),
    stationPQTSagas(),
    testPQTSagas(),
  ]);
}
