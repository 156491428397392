/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, notification, DatePicker, Button, Select, Radio, Divider
} from 'antd';
import {
    FilterFilled
} from '@ant-design/icons';
import moment from 'moment';
import { any } from 'prop-types';
import { OnFetchWeldTestDetails, OnGeneratePQtReports } from '../../redux/PQTlogDetails/saga';
// import { delay } from 'lodash';

const { Title, Text } = Typography;
const { Option } = Select;
var obj = {
    fromDate: '22-03-2022',
    toDate: '22-03-2023',
    sowId: 1,
    format: 'CSV',
    email: '',
    generatedBy: '',
    projectId: 1,
    generateReport: false
}
const GenerateReport = (props) => {

    const {
        isReportModalVisible,
        setIsReportModalVisible,
        setLocationData,
        locationData,
        projectIdValue,
        onGenerateReport,
        isLogReportsLoading,
        isWriteAccess, selectedSOW, selectedProject, pipelineList, setFileURL
    } = props;

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

    const [pipeline, setPipeline] = useState([]);
    const [format, setFormat] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [loadingReport, setLoadingReport] = useState(false);
    const [loadingWeldIds, setLoadingWeldIds] = useState(false);
    const [weldTestList, setWeldTestList] = useState([]);

    const [reportForm] = Form.useForm()

    // Function to fetch data based on date range
    const fetchWeldTestDetails = async (start, end) => {
        if (!selectedSOW) return;
        if (start && end) {
            var fromDate = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
            var toDate = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
            const obj = {
                startDate: fromDate,
                endDate: toDate,
                sowId: selectedSOW.id,
            };

            const list = await OnFetchWeldTestDetails(obj);
            console.log("Fetched Weld Test Data:", list);

            if (list && list.length > 0) {
                setWeldTestList(list);
            } else {
                setWeldTestList([]);
            }
        }
    };


    const showModal = async () => {
        setIsReportModalVisible(true);
        // if (selectedSOW)
        //     setPipeline(selectedSOW.pipelineSection)

        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {

            var userName = user?.name || ''
            setName(userName);

            reportForm.setFieldsValue({ name: userName });

        }

        const start = moment().startOf('month').format('YYYY-MM-DD')
        const end = moment().endOf('month').format('YYYY-MM-DD')
        setStartDate(start);
        setEndDate(end)

        // Set default current month dates & fetch data
        fetchWeldTestDetails(start, end);
    };






    const handleOk = () => {
        reportForm
            .validateFields()
            .then(async (data) => {
                if (!startDate && !endDate && !data.email && !data.name
                    // && !data.format
                ) {
                    notification.warning({
                        message: 'Select All Required Fields to proceed!'
                    });

                } else if (!startDate) {
                    notification.warning({
                        message: 'Select From Date to proceed!'
                    });
                } else if (!endDate) {
                    notification.warning({
                        message: 'Select To Date to proceed!'
                    });
                } else if (!data.email) {
                    notification.warning({
                        message: 'Provide Email to proceed!'
                    });
                } else if (!data.name) {
                    notification.warning({
                        message: 'Provide Name to proceed!'
                    });
                }
                else if (pipeline.length == 0) {
                    notification.warning({
                        message: 'Select Welds to proceed!'
                    });
                }
                else {
                    // onSave(data.stationName, selectedStation);
                    var sowId = selectedSOW.id

                    // if (data.location && pipelineList) {
                    //     pipelineList.filter((item) => {
                    //         if (item.pipelineSection == data.location) {
                    //             sowId = item.id
                    //         }
                    //     })
                    // }

                    let allWelds = pipeline.includes('All')
                        ? weldTestList.map(item => String(item.weldId))  // Ensure IDs are strings
                        : pipeline.filter(value => value !== "All").map(String);  // Convert to string
                    var fromDate = moment(startDate).startOf('day').toISOString();  // 00:00:00.000Z
                    var toDate = moment(endDate).endOf('day').toISOString();  // 23:59:59.999Z
                    console.log('selected weld IDs', allWelds)
                    var obj = {
                        fromDate: fromDate,
                        toDate: toDate,
                        sowId: sowId,
                        format: 'PDF',
                        weldIds: allWelds,
                        emailToDestinations: data.email,
                        generatedBy: data.name,
                        projectId: selectedProject.id,
                        generateReport: true
                    }
                    if (fromDate > toDate) {
                        notification.warn({
                            message: 'From date should be previous than To date'
                        });
                    } else {
                        setLoadingReport(true);
                        const reportUrl = await OnGeneratePQtReports(obj);
                        if (reportUrl) {
                            let fileName = reportUrl.split('/').pop(); // Gets the last part of the URL
                            fileName = decodeURIComponent(fileName); // Decodes any encoded characters

                            let a = document.createElement('a');
                            a.href = reportUrl;
                            a.download = fileName || "PQT_Log_Report.pdf"; // Use extracted filename, fallback if not available
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                            //setFileURL(reportUrl)

                            // let link = document.createElement('a')
                            // link.href = reportUrl
                            // a.download = fileName || "PQT_Log_Report.pdf";
                            // link.click()

                            //window.open(reportUrl, '_blank');
                            reportForm.resetFields(); //resetting input fields

                            setLoadingReport(false);
                            setIsReportModalVisible(false);
                            setStartDate(null)
                            setEndDate(null);
                            setPipeline([]);
                            setEmail('');
                            setName('')

                        // setTimeout(() => {
                        //     setLoadingReport(false);
                        //     setIsReportModalVisible(false);
                        //     setStartDate(null)
                        //     setEndDate(null);
                        //     setLocation('');
                        //     setEmail('');
                        //     setName('')
                        // }, 1000)
                    }
                }
            });
    };

    useEffect(() => {
        if (!isLogReportsLoading) {
            setLoadingReport(false);
            setIsReportModalVisible(false);
            setStartDate(null)
            setEndDate(null);
            setPipeline([]);
            setEmail('');
            setName('');
            setLoadingReport(false);
        }
    }, [isLogReportsLoading]);

    const handleCancel = () => {
        reportForm.resetFields(); //resetting input fields
        setIsReportModalVisible(false);
        setStartDate(null)
        setEndDate(null);
        setPipeline([]);
        setEmail('');
        setName('')
        setLoadingReport(false);
    };

    // const onKeyDown = (event) =>{
    //     console.log(event)
    //     if(event.which === 13 || event.which === 188 || event.which === 186) {
    //         console.log("done job", email)
    //     }
    // }


    // **Handle Start Date Change**
    const handleStartDateChange = async (date, dateString) => {
        setLoadingWeldIds(true)
        dateString = dateString && moment(dateString).isValid() ? moment(dateString).format("YYYY-MM-DD") : "";
        if (endDate && dateString > endDate) {
            notification.warn({ message: 'From date should be before To date' });
            return;
        }
        setStartDate(dateString);
        await fetchWeldTestDetails(dateString, endDate);
        setLoadingWeldIds(false)
    };

    // **Handle End Date Change**
    const handleEndDateChange = async (date, dateString) => {

        setLoadingWeldIds(true)
        dateString = dateString && moment(dateString).isValid() ? moment(dateString).format("YYYY-MM-DD") : "";
        setEndDate(dateString);
        await fetchWeldTestDetails(startDate, dateString);
        setLoadingWeldIds(false)
    };
    // const [subCategoryList, setSubCategoryList] = useState([]);
    // const processSubCategories = (data) => {
    //     const weldIds = [...new Set(data.map(item => item.weldId))]; // Extract unique weldIds

    //     // Ensure 'All' is the first option
    //     if (!weldIds.includes("All")) {
    //         weldIds.unshift("All");
    //     }

    //     return weldIds.map(weldId => ({ subCategory: weldId })); // Convert to objects
    // };

    // useEffect(() => {
    //     const result = processSubCategories(weldTestList);
    //     setSubCategoryList(result);
    // }, [weldTestList]);

    // const handleSelectChange = (values) => {
    //     console.log("Selected values:", values);

    //     // Extract all weld IDs



    //     if (values.includes("All")) {
    //         if (!pipeline.includes('All')) {
    //             const allWelds = weldTestList.map(item => item.weldId);

    //             const ids = values.filter(value => value !== "All")
    //             if (ids && ids.length > 0) {
    //                 setPipeline(ids);
    //             }
    //             else // If "All" is selected, select all weld IDs
    //                 setPipeline(values);
    //         }
    //         else {
    //             setPipeline(['All']);
    //         }

    //     }

    //     else if (values.length === 0) {
    //         // If everything is deselected, clear selection
    //         setPipeline([]);
    //     } else {
    //         // If specific items are selected, remove "All" and keep the selected ones
    //         setPipeline(values.filter(value => value !== "All"));
    //     }
    // };


    const handleSelectChange = (values) => {
        //console.log("Selected values:", values);

        // If "All" is selected, keep only "All"
        if (!pipeline.includes('All') && values.includes("All")) {
            setPipeline(["All"]);
        }
        // If other items are selected, remove "All"
        else {
            setPipeline(values.filter(value => value !== "All"));
        }
    };


    return (
        <>
            <div onClick={showModal} >
                <Button>Generate Report</Button>
            </div>
            <div>
                <Modal
                    width="40vw"
                    className="pipeline-modal"

                    title="Generate Report"

                    visible={isReportModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            Discard
                        </Button>,
                        <Button key="apply" type="primary" onClick={handleOk} loading={loadingReport}>
                            Confirm
                        </Button>,
                    ]}
                >



                    <Form
                        form={reportForm}
                        name="basic"
                        layout="vertical"

                    >
                        <Row gutter={24}>
                            <Col lg={12}>
                                <Form.Item
                                    className="date-picker"
                                    label="From"
                                    name="startDate"
                                    required
                                    // initialValue={moment(startDate, "YYYY-MM-DD")}
                                >
                                    <div>
                                        <DatePicker
                                            placholder='From'
                                            format="DD-MMM-YYYY"
                                            value={startDate ? moment(startDate) : null}
                                            onChange={(date, dateString) => handleStartDateChange(date, dateString)}
                                        />
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    className="date-picker"
                                    label="To"
                                    name="endDate"
                                    required
                                    // initialValue={moment(endDate, "YYYY-MM-DD")}
                                >
                                    <div>
                                        <DatePicker
                                            format="DD-MMM-YYYY"
                                            value={endDate ? moment(endDate) : null}
                                            onChange={(date, dateString) => handleEndDateChange(date, dateString)}
                                            disabledDate={current => startDate && current < moment(startDate)}
                                        />
                                    </div>
                                </Form.Item>
                            </Col>



                            <Col lg={12}>
                                <Form.Item
                                    label="Welds"
                                    name=""
                                    required
                                >
                                    <div>
                                        <Select
                                            className="multi-select"
                                            placeholder='Select Weld'
                                            loading={loadingWeldIds}
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            mode="multiple"  // Enable multiple selection
                                            onChange={handleSelectChange}
                                            value={pipeline}

                                            // filterOption={(input, option) =>
                                            //     option.children.toLowerCase().includes(input.toLowerCase())
                                            // }
                                            // dropdownRender={menu => (
                                            //     <>
                                            //         {menu}
                                            //         <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                            //         <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            //             <Input placeholder="Add Sub category" value={subCategory} onChange={(e) => setSubCategory(e.target.value)} />
                                            //             <Typography.Link onClick={addSubcategoryItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                            //                 <CheckOutlined />
                                            //             </Typography.Link>
                                            //         </Space>
                                            //     </>
                                            // )}
                                        >
                                            {/* {subCategoryList.map((item, i) => (
                                            <Option key={i} value={item.subCategory}>{item.subCategory}</Option>
                                        ))} */}


                                            {weldTestList.length > 0 && <Option key="all" value="All">All</Option>}
                                            {weldTestList.map((item) => (
                                                <Option key={item.weldId} value={item.weldId}>{item.weldId}</Option>
                                            ))}


                                        </Select>
                                    </div>
                                </Form.Item>
                            </Col>

                            <Col lg={12} >
                                <Form.Item
                                    label="Generated By"
                                    name="name"
                                    required
                                >
                                    <div>
                                        <Input
                                            // disabled
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            size="large"
                                            placeholder='Name or Email' />
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col lg={24} style={{ marginBottom: '24px' }}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    required
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
                                        // disabled
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        size="large"
                                        placeholder='Enter emails followed by comma (,)'
                                    // onKeyDown={onKeyDown}
                                    />

                                </Form.Item>
                                <span style={{ fontSize: '12px', position: 'relative', left: '10px' }}>eg: 1234@mail.com, 234@email.com</span>
                            </Col>

                        </Row>


                    </Form>

                </Modal>
            </div>
        </>
    );
};

export default GenerateReport;