import get from 'lodash/get';
import {
    FETCH_TEST_TYPES,
    SET_TEST_TYPES,
    FETCH_TEST_TYPES_FAILURE,
    ADD_TEST_DETAILS,
    ADD_TEST_DETAILS_FAILURE,
    FETCH_STATION_TEST_DETAILS,
    FETCH_STATION_TEST_DETAILS_FAILURE,
    ADD_TEST_DETAILS_SUCCESS,
    FETCH_STATION_TEST_DETAILS_SUCCESS,
    DELETE_TEST_DETAILS,
    UPDATE_TEST_DETAILS,
    UPLOAD_ITPADDSTATION_FILE,
    UPLOAD_ITPADDSTATION_FILE_SUCCESS,
    UPLOAD_ITPADDSTATION_FILE_FAILURE,
    FETCH_STATION_NAME,
    SET_STATION_NAME,
    FETCH_STATION_NAME_FAILURE
} from './types';

const initialState = {
    isTestLoading: false,
    isStationNameLoading: false,
    testTypes: [],
    stationNames: []
};

const testPQTReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEST_TYPES: {
            return {
                ...state,
                isTestLoading: true
            }
        }

        case SET_TEST_TYPES: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                testTypes: resData,
                isTestLoading: false
            }
        }

        case FETCH_TEST_TYPES_FAILURE: {
            return {
                ...state,
                isTestLoading: false
            }
        }

        case FETCH_STATION_NAME: {
            return {
                ...state,
                isStationNameLoading: true
            }
        }

        case SET_STATION_NAME: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                stationNames: resData,
                isStationNameLoading: false
            }
        }

        case FETCH_STATION_NAME_FAILURE: {
            return {
                ...state,
                isStationNameLoading: false
            }
        }

        case ADD_TEST_DETAILS: {
            return {
                ...state,
                isTestLoading: true
            }
        }
        case ADD_TEST_DETAILS_SUCCESS: {
            return {
                ...state,
                isTestLoading: false
            }
        }
        case ADD_TEST_DETAILS_FAILURE: {
            return {
                ...state,
                isTestLoading: false
            }
        }
        case FETCH_STATION_TEST_DETAILS: {
            return {
                ...state,
                isTestLoading: true
            }
        }
        case FETCH_STATION_TEST_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                projStationTestData: resData,
                isTestLoading: false
            }
        }
        case FETCH_STATION_TEST_DETAILS_FAILURE: {
            return {
                ...state,
                isTestLoading: false
            }
        }
        case DELETE_TEST_DETAILS: {
            return {
                ...state,
                isTestLoading: true
            }
        }
        case UPDATE_TEST_DETAILS: {
            return {
                ...state,
                isTestLoading: true
            }
        }
        case UPLOAD_ITPADDSTATION_FILE: {
            return {
                ...state,
                isTestLoading: true
            }
        }

        case UPLOAD_ITPADDSTATION_FILE_SUCCESS: {
            return {
                ...state,
                isTestLoading: false
            }
        }

        case UPLOAD_ITPADDSTATION_FILE_FAILURE: {
            return {
                ...state,
                isTestLoading: false
            }
        }

        default: {
            return state;
        }
    }
};

export default testPQTReducer;
