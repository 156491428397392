import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchLogDetailsSuccess,
    fetchLogDetailsFailure,
    generateLogReportsSuccess,
    generateLogReportsFailure,
    fetchCalenderDetailsSuccess,
    fetchCalenderDetailsFailure
} from './action';
import {
    FETCH_CALENDER_DETAILS,
    FETCH_LOG_DETAILS, GENERATE_LOG_REPORTS
} from './types';
import axiosConfig from './axiosConfig1';
import moment from 'moment';

var count = 0;

/** Get all Log Details (Inspection Details) */
function* fetchLogDetails(data) {
    try {

        const res = yield axiosConfig.get(`/report/inspection-report-data?fromDate=${data.fromDate}&toDate=${data.toDate}&sowId=${data.sowId}&pageSize=${data.pageSize}&pageIndex=${data.pageIndex}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put(fetchLogDetailsSuccess({ response }));
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchLogDetailsFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Log Details.',
            description: `${error}`,
        });
    }
}

/** Generate Reports (Inspection Details) */
function* generateLogReports(data) {

    try {
        const utc = (new Date().getTimezoneOffset()).toString();
        const res = yield axiosConfig.get(`/report/generate-inspection-report?fromDate=${data.fromDate}&toDate=${data.toDate}&sowId=${data.sowId}&format=${data.format}&email=${data.email}&generatedBy=${data.generatedBy}&projectId=${data.projectId}&reportGeneratedTime=${moment().format("YYYY-MM-DD HH:mm:ss")}&offsetFromUtc=${utc}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {

            if (response.displayMessage === "Report generated sucessfully!!") {
                count = 0;
                //download Document
                let a = document.createElement('a');
                a.href = response.reportUrl;
                // a.download = `Inspection Report.${data.format}`;
                a.click();

                notification.success({
                    message: response.displayMessage,
                });
                yield put(generateLogReportsSuccess({ response }));
            } else {
                yield put(generateLogReportsFailure());
                notification.warn({
                    message: response.displayMessage,
                });
            }
        } else {
            yield put(generateLogReportsFailure());
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        if (count === 1) {
            yield put(generateLogReportsFailure());
            notification.destroy();
            if (error.error === "email must be a valid email") {
                notification.error({
                    message: `${error.error}`,
                    // description: `${error.error}`,
                });
            } else {
                notification.error({
                    message: `${error}`,
                    // description: `${error.error}`,
                });
            }
        } else {
            count = 1;
            yield generateLogReports(data);
        }
    }
}


/** Get all Log Details (Inspection Details) */
function* fetchCalenderDetails(data) {
    try {

        const res = yield axiosConfig.get(`/weld/calendar-view-data?sowId=${data.sowId}&year=${data.year}&month=${data.month}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put(fetchCalenderDetailsSuccess({ response }));
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchCalenderDetailsFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Calender Details.',
            description: `${error}`,
        });
    }
}

function* logPQTWatcher() {
    yield takeLatest(FETCH_LOG_DETAILS, fetchLogDetails);
    yield takeLatest(GENERATE_LOG_REPORTS, generateLogReports);
    yield takeLatest(FETCH_CALENDER_DETAILS, fetchCalenderDetails);
}

function* logPQTSagas() {
    yield all([
        call(logPQTWatcher),
    ]);
}

export default logPQTSagas;



/** Generate Reports (PQT Details) */
export async function OnGeneratePQtReports(data) {
    try {
        const utc = (new Date().getTimezoneOffset()).toString();
        data['offsetFromUtc'] = utc
        data['reportGeneratedTime'] = moment().format("YYYY-MM-DD HH:mm:ss")

        // const res = await axiosConfig.get(`/report/generate-pqt-report?fromDate=${data.fromDate}&toDate=${data.toDate}&sowId=${data.sowId}&format=${data.format}&emailToDestinations=${data.email}&generatedBy=${data.generatedBy}&projectId=${data.projectId}&reportGeneratedTime=${moment().format("YYYY-MM-DD HH:mm:ss")}&offsetFromUtc=${utc}&weldIds=${data.weldIds}`);

        const res = await axiosConfig.post('report/generate-pqt-report', data)
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {

            if (response.displayMessage === "Report generated sucessfully!!") {
                count = 0;
                // //download Document
                // let a = document.createElement('a');
                // a.href = response.reportUrl;
                // // a.download = `Inspection Report.${data.format}`;
                // a.click();

                notification.success({
                    message: response.displayMessage,
                });
                return response.reportUrl;
                //yield put(generateLogReportsSuccess({ response }));
            } else {
                //  yield put(generateLogReportsFailure());
                notification.warn({
                    message: response.displayMessage,
                });
                return null
            }
        } else {
            // yield put(generateLogReportsFailure());
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
            return null
        }
    } catch (error) {
        if (count === 1) {
            // yield put(generateLogReportsFailure());
            notification.destroy();
            if (error.error === "email must be a valid email") {
                notification.error({
                    message: `${error.error}`,
                    // description: `${error.error}`,
                });
            } else {
                notification.error({
                    message: `${error}`,
                    // description: `${error.error}`,
                });
            }
        } else {
            // count = 1;
            //await generateLogReports(data);
        }
    }
}


/** Get all Log Details (Inspection Details) */
export async function OnFetchCalenderDetails(data) {
    try {

        const res = await axiosConfig.get(`/pqt-weld/calendar-view-data?sowId=${data.sowId}&year=${data.year}&month=${data.month}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            return response;
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
            return null
        }
    } catch (error) {
        
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Calender Details.',
            description: `${error}`,
        });
      return null 
    }
}


export async function OnFetchWeldTestDetails(data) {
    try {

        const res = await axiosConfig.get(`/pqt-welds?sowId=${data.sowId}&startDate=${data.startDate}&endDate=${data.endDate}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            return response;
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
            return null
        }
    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Calender Details.',
            description: `${error}`,
        });
        return null
    }
}

export async function OnFetchLogDetails(data) {
    try {

        const res = await axiosConfig.get(`/pqt-report/inspection-report-data?fromDate=${data.fromDate}&toDate=${data.toDate}&sowId=${data.sowId}&pageSize=${data.pageSize}&pageIndex=${data.pageIndex}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
           return response
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
            return null
        }
    } catch (error) {
       
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Log Details.',
            description: `${error}`,
        });
        return null
    }
}