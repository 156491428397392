/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';

const { Option } = Select;

const AddNewShipmentNumber = (props) => {

    const {
        showAddModel,setShowAddModel
    } = props;

    const [equipmentId, setEquipmentId] = useState('');
  const [equipTypeForm] = Form.useForm();
   

    /** Adding or Updating Equipment types */
    const handleOk = () => {

    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setShowAddModel(false)
        //equipTypeForm.resetFields();
    }


    return (
        <div>
            <Modal
                className="pipeline-modal"
                title={'Create New Shipment Number'}
                centered
                visible={showAddModel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk}>
                        Add
                    </Button>
                ]}
            >
                <Form
                    form={equipTypeForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getContainer={false}
                >
                    <Row gutter={24}>
                       
                        <Col lg={24}>
                            <Form.Item
                                label="Shipment Number"
                                name="Shipment_Number"
                                required
                            >
                                <Input
                                    placeholder={ 'Enter New Shipmment Number'}
                                   // value={equipmentType}
                                  //  onChange={(e) => setEquipmentType(e.target.value)}
                                    size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

 

export default AddNewShipmentNumber;