// ITP Section (Inspection Test) - Test for station details
export const FETCH_TEST_TYPES = '@@stationPQT/list-test-types';
export const SET_TEST_TYPES = '@@stationPQT/set-test-types';
export const FETCH_TEST_TYPES_FAILURE = '@@stationPQT/set-test-types-failure';

export const FETCH_STATION_NAME = '@@stationPQT/list-station-names';
export const SET_STATION_NAME = '@@stationPQT/set-station-names';
export const FETCH_STATION_NAME_FAILURE = '@@stationPQT/set-station-names-failure';

export const ADD_TEST_DETAILS = '@@stationPQT/add-test-details';
export const ADD_TEST_DETAILS_SUCCESS = '@@station/add-test-details-success';
export const ADD_TEST_DETAILS_FAILURE = '@@station/add-test-details-failure';

export const FETCH_STATION_TEST_DETAILS = '@@stationPQT/fetch-station-test-details';
export const FETCH_STATION_TEST_DETAILS_SUCCESS = '@@stationPQT/fetch-station-test-details-success';
export const FETCH_STATION_TEST_DETAILS_FAILURE = '@@stationPQT/fetch-station-test-details-failure';

export const DELETE_TEST_DETAILS = '@@testPQT/delete-test-details'
export const UPDATE_TEST_DETAILS = '@@test/update-test-details'

//
export const UPLOAD_ITPADDSTATION_FILE = '@@sowPQT/upload-itp-add-station-File';
export const UPLOAD_ITPADDSTATION_FILE_SUCCESS = '@@sowPQT/upload-itp-add-station-File-success';
export const UPLOAD_ITPADDSTATION_FILE_FAILURE = '@@sowPQT/upload-itp-add-station-File-failure';