/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, Select, notification, InputNumber, Button, Radio, Checkbox, Table
} from 'antd';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const AddNewPQTTest = (props) => {

    const {
        isTestModalVisible,
        setIsTestModalVisible,
        sowStationId,
        testTypesList,
        onSaveTestDetails,
        criteriaList,
        inputValue,
        setInputValue,
        valueObj,
        setValueObj,
        testTypeValue,
        setTestTypeValue,
        criteria,
        setCriteria,
        testValue,
        setTestValue,
        testForm,
        setEditTest,
        editTest,
        editTestId,
        // loadingBtn,
        // setLoadingBtn,
        onSaveEditTestDetails,
        imagesRequired,
        setImagesRequired,
        stopwatchValueRequired,
        setStopwatchValueRequired,
        comparator,
        setComparator,
        selectedSOW, selectedTestData,
        projectTeamMembersAccessList
    } = props;
    // console.log("criteriaList", criteriaList)
    const comparatorValue = ["<", "<=", ">", ">=", "=", "BETWEEN"];

    const [noOfSample, setNoOfSample] = useState(1);
    const [testId, setTestId] = useState('');
    const [minValue, setMinValue] = useState('0');
    const [maxValue, setMaxValue] = useState('0');
    const [mandatory, setMandatory] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [isTableSelected, setIsTableSelected] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [colCount, setColCount] = useState(0);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [form] = Form.useForm();

    // Separate useState for each input field
    const [processDescription, setProcessDescription] = useState('');
    const [applicableStandardsCode, setApplicableStandardsCode] = useState('');
    const [testEquipment, setTestEquipment] = useState('');
    const [acceptanceCriteria, setAcceptanceCriteria] = useState('');
    const [dataTable, setDataTable] = useState({
        rows: [], // Initially empty
        columns: []
    });

    useEffect(() => {
        // console.log(editTest, inputValue, selectedTestData)
        if (editTest) {

            setProcessDescription(selectedTestData.processDescription)
            setApplicableStandardsCode(selectedTestData.applicableStandardsCode)
            setTestEquipment(selectedTestData.testEquipment)
            setAcceptanceCriteria(selectedTestData.acceptanceCriteria)

            var editField = {
                mandatory: true,
                processDescription: selectedTestData.processDescription,
                applicableStandardsCode: selectedTestData.applicableStandardsCode,
                testEquipment: selectedTestData.testEquipment,
                acceptanceCriteria: selectedTestData.acceptanceCriteria,
            }
            if (criteria === 'Table') {
                if (selectedTestData.tableData) {
                    setDataTable(selectedTestData.tableData)
                    editField.colCount = selectedTestData.tableData.columns.length
                    editField.rowCount = selectedTestData.tableData.rows.length
                    setRowCount(selectedTestData.tableData.rows.length)
                    setColCount(selectedTestData.tableData.columns.length)

                }

                setInputValue(false)
                setIsTableSelected(true)
            }
            else if (criteria === 'Value') {
                setRowCount(0)
                setColCount(0)
                // setDataTable([])

                setIsTableSelected(false)
                editField.noOfSample = selectedTestData.noOfSample
                setNoOfSample(selectedTestData.noOfSample)
            }
            //  onChangeCriteria(criteria)
            testForm.setFieldsValue(editField);
        }


    }, [isTestModalVisible])



    const showModal = () => {
        setIsTestModalVisible(true);
        setInputValue(false);
        setEditTest(false);
        sessionStorage.setItem('sowStationId', sowStationId);
        var editField = {
            mandatory: true,

        }
        setProcessDescription('')
        setApplicableStandardsCode('')
        setTestEquipment('')
        setAcceptanceCriteria('')
        testForm.setFieldsValue(editField);

        //setting test mandatory by default
    };

    /** Function to reset fields on cancel */
    const handleCancel = () => {
        testForm.resetFields(); //resetting input fields
        setIsTestModalVisible(false);
        setInputValue(false);
        setValueObj('');
        setTestTypeValue('')
        setImagesRequired(false);
        setStopwatchValueRequired(false);
        setComparator('<');
        setColCount(0);
        setRowCount(0);
        setProcessDescription('')
        setApplicableStandardsCode('')
        setTestEquipment('')
        setAcceptanceCriteria('')

    };

    /** Function to save (add or edit test) */
    const handleOk = () => {
        testForm
            .validateFields()
            .then(async (data) => {
                if (data.criteria !== undefined) {
                    // Check if the required fields are empty
                    if (!data.testId || !data.criteria) {
                        notification.warning({
                            message: 'Fill All the required fields to proceed!'
                        });
                        return;
                    }

                    var sowStatId = sessionStorage.getItem('sowStationId');

                    if (data.criteria === 'Accept/Reject/NA') {
                        const obj = {
                            processDescription: processDescription,
                            applicableStandardsCode: applicableStandardsCode,
                            testEquipment: testEquipment,
                            acceptanceCriteria: acceptanceCriteria,
                            testId: data.testId,
                            noOfSample: null,
                            tableData: null,
                            typeOfTest: 'Accept/Reject/NA',
                            "sowStationId": parseInt(sowStatId),
                            testValueTypeId: testTypeValue.id,
                            mandatory: data.mandatory,
                            imagesRequired: imagesRequired,
                            stopwatchValueRequired: stopwatchValueRequired
                        }
                        setLoadingBtn(true);
                        if (editTest) {
                            await onSaveEditTestDetails(obj, editTestId);
                        } else {
                            await onSaveTestDetails(obj);
                        }

                        setLoadingBtn(false);
                        setIsTestModalVisible(false);
                        setCriteria('')
                        setInputValue(false);
                        setTestValue('');
                        setImagesRequired(false);
                        setStopwatchValueRequired(false);
                        setComparator('<');
                        testForm.resetFields(); //resetting input fields

                    } else if (data.criteria === 'Table') {

                        if (!data.testValue) {
                            notification.warning({
                                message: 'Select Value Unit to Proceed!'
                            });
                            return;
                        }
                        const obj = {
                            processDescription: processDescription,
                            applicableStandardsCode: applicableStandardsCode,
                            testEquipment: testEquipment,
                            acceptanceCriteria: acceptanceCriteria,
                            tableData: dataTable,
                            testId: data.testId,
                            noOfSample: null,
                            typeOfTest: 'Table',
                            "sowStationId": parseInt(sowStatId),
                            testValueTypeId: testTypeValue.id,
                            mandatory: data.mandatory,
                            imagesRequired: imagesRequired,
                            stopwatchValueRequired: stopwatchValueRequired
                        }
                        setLoadingBtn(true);
                        if (editTest) {
                            await onSaveEditTestDetails(obj, editTestId);
                        } else {
                            await onSaveTestDetails(obj);
                        }

                        setLoadingBtn(false);
                        setIsTestModalVisible(false);
                        setCriteria('')
                        setInputValue(false);
                        setTestValue('');
                        setImagesRequired(false);
                        setStopwatchValueRequired(false);
                        setComparator('<');
                        testForm.resetFields(); //resetting input fields


                    }

                    else if (data.testValue !== undefined) {
                        if ((comparator == '<' || comparator == '<=') && (typeof data.maxValue === 'undefined' || data.maxValue <= 0)) {
                            notification.warn({ message: 'Max Value should be greater than 0' });
                            return;
                        }
                        if ((comparator == '>' || comparator == '>=') && (typeof data.minValue === 'undefined' || data.minValue <= 0)) {
                            notification.warn({ message: 'Min Value should be greater than 0' });
                            return;
                        }
                        if (comparator == 'BETWEEN' && (typeof data.minValue === 'undefined' || data.minValue < 0)) {
                            notification.warn({ message: 'Min Value should be greater than 0 or 0' });
                            return
                        }
                        if (comparator == 'BETWEEN' && (typeof data.minValue === 'undefined' || typeof data.maxValue === 'undefined' || data.minValue >= data.maxValue)) {
                            notification.warn({ message: 'Max Value should be greater than Min Value' });
                            return
                        }
                        if (comparator == '=' && (typeof data.maxValue === 'undefined' || data.maxValue <= 0)) {
                            notification.warn({ message: 'Value should be greater than 0' });
                            return
                        }
                        setLoadingBtn(true);
                        const obj = {
                            processDescription: processDescription,
                            applicableStandardsCode: applicableStandardsCode,
                            testEquipment: testEquipment,
                            acceptanceCriteria: acceptanceCriteria,
                            testId: data.testId,
                            typeOfTest: 'Value',
                            tableData: null,
                            noOfSample: noOfSample,
                            "sowStationId": parseInt(sowStatId),
                            testValueTypeId: testTypeValue.id,
                            minValue: +data.minValue,
                            comparator: comparator,
                            maxValue: +data.maxValue,
                            mandatory: data.mandatory,
                            imagesRequired: imagesRequired,
                            stopwatchValueRequired: stopwatchValueRequired
                        }
                        if (editTest) {
                            if (data.testValue) {

                                await onSaveEditTestDetails(obj, editTestId);


                                setIsTestModalVisible(false);
                                setCriteria('')
                                setInputValue(false);
                                setTestValue('');
                                setMaxValue('');
                                setMinValue('');
                                setImagesRequired(false);
                                setStopwatchValueRequired(false);
                                setComparator('<');
                                testForm.resetFields(); //resetting input fields

                            } else {
                                notification.warning({
                                    message: 'Select Value Unit to Proceed!'
                                });
                            }
                        } else {

                            await onSaveTestDetails(obj);
                            setLoadingBtn(false);
                            setIsTestModalVisible(false);
                            setCriteria('')
                            setInputValue(false);
                            setTestValue('');
                            setMaxValue('');
                            setMinValue('');
                            setImagesRequired(false);
                            setStopwatchValueRequired(false);
                            setComparator('<');
                            testForm.resetFields(); //resetting input fields
                            setLoadingBtn(true);

                        }

                        setLoadingBtn(false);
                    } else {
                        notification.warning({
                            message: 'Select Value Unit to Proceed!'
                        });
                    }
                } else {
                    notification.warning({
                        message: 'Fill All the required fields to proceed!'
                    });
                }
            })
            .catch((error) => {
                console.error('Validation Error:', error);
            });
    };


    /** Function for on select of Criteria */
    const onChangeCriteria = (value) => {
        setCriteria(value)
        if (criteriaList.length > 0) {
            const selected = criteriaList.find(u => u.value === value);
            if (selected && selected.id === 1) {
                setValueObj(selected.types);
                const testValueTypeId = testTypesList && testTypesList.find(u => u.valueType === selected.valueType);
                setTestTypeValue(testValueTypeId)
                setInputValue(false);
                setIsTableSelected(false);
            } else if (selected && selected.id === 3) {
                setValueObj(selected.types);
                setIsTableSelected(true);
                setInputValue(false);
            } else {

                setValueObj(selected.types);
                setTestTypeValue('')
                setInputValue(true)
                setIsTableSelected(false)
            }
        }
    };

    /** Function for on select of unit value */
    const onChangeValue = (value) => {
        setTestValue(value);
        if (valueObj.length > 0) {
            const selected = valueObj.find(u => u.value === value);
            const testValueTypeId = testTypesList && testTypesList.find(u => u.valueType === selected.valueType);
            setTestTypeValue(testValueTypeId);
        }

    };

    const onChangeCompValue = (value) => {
        setComparator(value);
        if (value === '>' || value === '>=') {
            setMaxValue('0');
            testForm.setFieldsValue({ maxValue: '0' });
        } else if (value === '<' || value === '<=' || value === '=') {
            testForm.setFieldsValue({ minValue: '0' });
            setMinValue('0');
        }
    };

    // Function to generate table structure
    // const generateTable = () => {
    //     const newTableData = Array.from({ length: rows }, (_, rowIndex) => ({
    //         rowName: `Row ${rowIndex + 1}`,
    //         columns: Array.from({ length: columns }, (_, colIndex) => ({
    //             colName: `Column ${colIndex + 1}`,
    //             value: ''
    //         }))
    //     }));
    //     setTableData(newTableData);
    // };

    // // Handle input change in the table
    // const handleInputChange = (rowIndex, colIndex, value) => {
    //     const updatedTable = [...tableData];
    //     updatedTable[rowIndex].columns[colIndex].value = value;
    //     setTableData(updatedTable);
    // };

    // Handle row and column count input
    const handleRowColumnChange = () => {
        const rowNum = testForm.getFieldValue("rowCount") || 0;
        const colNum = testForm.getFieldValue("colCount") || 0;
        setRowCount(rowNum);
        setColCount(colNum);

        // // Create empty row names and column names
        // setRows(Array.from({ length: rowNum }, (_, i) => `Row ${i + 1}`));

        // setColumns(Array.from({ length: colNum }, (_, i) => `Column ${i + 1}`));

        // Update state with new row/column structure
        setDataTable(() => {
            return {
                rows: Array.from({ length: rowNum }, (_, i) => ({
                    name: `Row ${i + 1}`,
                    values: Array.from({ length: colNum }, (_, i) => '') // Empty values for each row
                })),
                columns: Array.from({ length: colNum }, (_, i) => `Column ${i + 1}`)
            };
        });

    };

    // Update row name while preserving values
    const updateRowName = (index, newName) => {
        setDataTable((prevData) => {
            const updatedRows = [...prevData.rows];
            updatedRows[index] = { ...updatedRows[index], name: newName };
            return { ...prevData, rows: updatedRows };
        });
    };

    // Update column name
    const updateColumnName = (index, newName) => {
        setDataTable((prevData) => {
            const updatedColumns = [...prevData.columns];
            updatedColumns[index] = newName;
            return { ...prevData, columns: updatedColumns };
        });
    };

    const gettableColumns = () => {
        // Generate Table Columns
        const tableColumns = [
            {
                title: <div className="row-title">Rows / Columns</div>,
                dataIndex: "rowName",
                key: "rowName",
                fixed: "left",
            },
            ...dataTable.columns.map((col, index) => ({
                title: (
                    <Input
                        className="row-title"
                        value={col}
                        onChange={(e) => updateColumnName(index, e.target.value)}
                        placeholder={`Column ${index + 1}`}
                    />
                ),
                dataIndex: `col_${index}`,
                key: `col_${index}`,
                render: (_, row) => (
                    <Input disabled
                        value={row.values[index] || ""}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            setDataTable((prevData) => {
                                const updatedRows = [...prevData.rows];
                                updatedRows[row.key].values[index] = newValue;
                                return { ...prevData, rows: updatedRows };
                            });
                        }}
                    />
                ),
            })),
        ];
        return tableColumns
    }
    const getTableData = () => {


        // Generate Table Data
        const tableData = dataTable.rows.map((row, rowIndex) => ({
            key: rowIndex,
            rowName: (
                <Input
                    value={row.name}
                    onChange={(e) => updateRowName(rowIndex, e.target.value)}
                    placeholder={`Row ${rowIndex + 1}`}
                />
            ),
            values: row.values,
        }));
        return tableData
    }


    return (
        <>
            <div onClick={showModal} className="new-test">
                <Text className="add-new-test" >
                    <span className="icon-20">
                        <svg>
                            <use xlinkHref="#add" />
                        </svg>
                    </span>
                    Add Test
                </Text>
            </div>

            <Modal
                width="62vw"
                getContainer={false}
                className="pipeline-modal"
                title={editTest ? "Edit Test" : "Add Test"}
                centered
                visible={isTestModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={loadingBtn}>
                        {editTest ? "Update" : "Add"}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={testForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>

                            <Col lg={12}>
                                <Form.Item
                                    label="Process Description"
                                    name="processDescription"
                                    required
                                >
                                    <TextArea
                                        value={processDescription}
                                        size="large"
                                        onChange={(e) => setProcessDescription(e.target.value)}
                                        style={{
                                            height: 60,    // Fixed height
                                            overflowY: "auto",  // Enables scrollbar when needed
                                            resize: "none"  // Prevents manual resizing
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Applicable Standards & Code"
                                    name="applicableStandardsCode"
                                    required
                                >
                                    <TextArea
                                        value={applicableStandardsCode}
                                        size="large"
                                        onChange={(e) => setApplicableStandardsCode(e.target.value)}
                                        style={{
                                            height: 60,    // Fixed height
                                            overflowY: "auto",  // Enables scrollbar when needed
                                            resize: "none"  // Prevents manual resizing
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Test Equipment"
                                    name="testEquipment"
                                    required
                                >
                                    <TextArea
                                        value={testEquipment}
                                        size="large"
                                        onChange={(e) => setTestEquipment(e.target.value)}
                                        style={{
                                            height: 60,    // Fixed height
                                            overflowY: "auto",  // Enables scrollbar when needed
                                            resize: "none"  // Prevents manual resizing
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Acceptance Criteria"
                                    name="acceptanceCriteria"
                                    required
                                >
                                    <TextArea
                                        value={acceptanceCriteria}
                                        size="large"
                                        onChange={(e) => setAcceptanceCriteria(e.target.value)}
                                        style={{
                                            height: 60,    // Fixed height
                                            overflowY: "auto",  // Enables scrollbar when needed
                                            resize: "none"  // Prevents manual resizing
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    label="Test ID"
                                    name="testId"
                                    size="large"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Test ID'
                                        },
                                        {
                                            max: 5,
                                            message: 'Test ID length must be less than or equal to five characters'
                                        }
                                    ]}
                                >
                                    <Input
                                        value={testId}
                                        size="large"
                                        onChange={(e) => setTestId(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col lg={6}>
                                <Form.Item
                                    label="Criteria"
                                    name="criteria"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Criteria'
                                        onChange={onChangeCriteria}
                                        value={criteria}
                                        size="large"
                                    >
                                        {criteriaList && criteriaList.map((item, i) =>
                                            <Option key={i} value={item && item.value}>{item && item.value}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {
                                isTableSelected && (
                                    <>

                                        <Col lg={6}>
                                            <Form.Item
                                                label="Unit"
                                                name="testValue"
                                                required
                                            >
                                                <Select
                                                    className='client-select'
                                                    placeholder='Select Unit'
                                                    onChange={onChangeValue}
                                                    value={testValue}
                                                >
                                                    {valueObj && valueObj
                                                        .filter(item => (item.value !== undefined && item.value !== '') && item.value !== 'Accept/Reject/NA')
                                                        .map((item, i) => (
                                                            <Option key={i} value={item.value}>{item.value}</Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="No of Rows" name="rowCount">
                                                <Input type="number" min={1} onChange={handleRowColumnChange} size='large' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="No of Columns" name="colCount">
                                                <Input type="number" min={1} onChange={handleRowColumnChange} size='large' />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )
                            }
                            {
                                inputValue &&
                                <Col lg={6}>
                                    <Form.Item
                                        label="Number of sample"
                                            name="noOfSample"
                                        required
                                    >
                                        <Input
                                                value={noOfSample}
                                                onChange={(e) => setNoOfSample(e.target.value)}
                                            size="large"
                                            type='number'
                                            min={1}
                                            defaultValue={1}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            {inputValue &&
                                <>
                                <Col lg={6}>
                                        <Form.Item
                                            label="Unit"
                                            name="testValue"
                                            required
                                        >
                                            <Select
                                                className='client-select'
                                                placeholder='Select Unit'
                                                onChange={onChangeValue}
                                                value={testValue}
                                            >
                                            {valueObj && valueObj
                                                .filter(item => (item.value !== undefined && item.value !== '') && item.value !== 'Accept/Reject/NA')
                                                .map((item, i) => (
                                                    <Option key={i} value={item.value}>{item.value}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                <Col lg={6}>
                                    <Form.Item
                                        label="Comparator"
                                        name="comparator"
                                        required
                                    >
                                        <Select
                                            className='client-select'
                                            placeholder='Select Comparator'
                                            onChange={onChangeCompValue}
                                            value={comparator}
                                            defaultValue={comparatorValue[0]}
                                        >
                                            {comparatorValue.map((item, i) => (
                                                <Option key={i} value={item}>{item}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {(comparator == '>' || comparator == '>=' || comparator == 'BETWEEN') && <Col lg={6}>
                                    <Form.Item
                                        label="Min. Value"
                                        name="minValue"
                                        required
                                    >
                                        <InputNumber
                                            value={minValue}
                                            type='number'
                                            onChange={(value) => setMinValue(value)}
                                            size="large"
                                            disabled={comparator == '<' || comparator == '<=' || comparator == '='}
                                        />
                                    </Form.Item>
                                </Col>}

                                {(comparator == '<' || comparator == '<=' || comparator == '=' || comparator == 'BETWEEN') && <Col lg={6}>
                                    <Form.Item
                                        label={comparator == '=' ? "Value" : "Max. Value"}
                                        name="maxValue"
                                        required
                                    >
                                        <InputNumber
                                            value={maxValue}
                                            type='number'
                                            min={comparator == 'BETWEEN' ? parseInt(minValue) : undefined}
                                            onChange={(value) => { setMaxValue(value) }}
                                            size="large"
                                            disabled={comparator == '>' || comparator == '>='}
                                        />
                                        </Form.Item>
                                </Col>}
                                </>
                            }
                        </Row>
                        {/* {
                            isTableSelected && (
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Form.Item label="Number of Rows" name="rowCount">
                                            <Input type="number" min={1} onChange={handleRowColumnChange} size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Number of Columns" name="colCount">
                                            <Input type="number" min={1} onChange={handleRowColumnChange} size='large' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        } */}
                        {/* Dynamic Table */}
                        {isTableSelected && rowCount > 0 && colCount > 0 && (
                            <Table
                                columns={gettableColumns()}
                                dataSource={getTableData()}
                                bordered
                                pagination={false}
                                className='itp-container'
                            />
                        )}
                        {/* </Form> */}
                        <Row gutter={16}>
                            <Col lg={6}>
                                <Form.Item
                                    name="mandatory"
                                    required
                                    className='report-radio'
                                >
                                    <Radio.Group onChange={e => setMandatory(e.target.value)} value={mandatory} style={{ display: "flex" }}>
                                        <Radio value={true}>Mandatory</Radio>
                                        <Radio value={false}>Optional</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col lg={14}>
                                <Text style={{ fontSize: "14px", marginTop: '2px', color: "orangered" }}>
                                    {mandatory ?
                                        "* Test required for all the welds."
                                        : "* Test is not required for all the welds."}
                                </Text>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="imagesRequired"
                                    className='report-checkbox'
                                >
                                    <Checkbox checked={imagesRequired} onChange={e => setImagesRequired(e.target.checked)}>Images</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col lg={14}>
                                <Text style={{ fontSize: "14px", color: "orangered" }}>
                                    {imagesRequired ?
                                        "* For this test, image is required."
                                        : "* For this test, image is not required."}
                                </Text>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="stopwatchValueRequired"
                                    className='report-checkbox'
                                >
                                    <Checkbox checked={stopwatchValueRequired} onChange={e => setStopwatchValueRequired(e.target.checked)}>StopWatch</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col lg={14}>
                                <Text style={{ fontSize: "14px", color: "orangered" }}>
                                    {stopwatchValueRequired ?
                                        "* For this test, Stopwatch is required."
                                        : "* For this test, Stopwatch  is not required."}
                                </Text>
                            </Col>
                        </Row>

                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default AddNewPQTTest;