/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable eol-last */

import React, { useState, useEffect } from 'react';
import {
  Layout, Menu, Avatar, Button, notification, Typography, Modal, Col, Row, Popover, Input, Form, Tooltip, Spin
} from 'antd';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import Logo from '../assets/fsa_logo_withtext.svg';
import LogoBlack from '../assets/fsa_logo_withtext_black.svg';
import LogoSmall from '../assets/fsa_logo.png';
import logoCrc from '../assets/logoCrc.png';
import smartLync from '../assets/smartLync.png'
// import history from '../services/history';
import { signOut } from '../redux/actions/authActions';
import { UserOutlined, EyeOutlined, EyeInvisibleOutlined, CloseOutlined, HeatMapOutlined, ContactsOutlined, ContainerOutlined } from '@ant-design/icons';
import * as constants from '../utils/constants'
import { passwordPolicy } from '../utils/passwordPolicy';
import { changeProfilePassword } from '../services/auth';
import { valuesIn } from 'lodash';
import {
  SettingOutlined, ApiOutlined
} from '@ant-design/icons';
import { authenticateCurrentUser, getAwsGroupAccess } from '../services/auth';
import { getAccess } from '../services/teamMembers';
import { useHistory } from 'react-router-dom';

const { Sider } = Layout;
const Sidebar = (props) => {
  const {
    onSignout, setIsShowProjectData
  } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [switchtheme, setSwitchtheme] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [validatingStatus, setValidatingStatus] = useState('');
  const [validatingStatusConfirm, setValidatingStatusConfirm] = useState('');
  const [validateVisible, setValidateVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loadBtn, setLoadBtn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  const [loadingUserGroup, setLoadingUserGroup] = useState(false);
  const [changePasswordForm] = Form.useForm();
  const [selectedKey, setSelectedKey] = useState('1');
  const history = useHistory(); 

  const onCollapse = (collapse) => {
    setCollapsed(collapse);
  };

  // const user = JSON.parse(localStorage.getItem('CognitoIdentityServiceProvider.584pe71ujm7l94dqj0emdefnjt.4835dcc2-01c6-4d4c-b876-5811ccdee87f.userData'));
  const user = JSON.parse(localStorage.getItem("user"));


  useEffect(() => {
    const element = document.getElementById('body-wrapper');
    const theme = sessionStorage.getItem('theme');
    if (theme === 'True') {
      element.classList.add('light-theme');
    } else {
      element.classList.remove('light-theme');
    }
  }, [switchtheme]);

  /** get user Access */
  useEffect(() => {
    authenticateCurrentUser()
      .then(async (data) => {
        setUserName(data.username);
        setUserGroup(data.signInUserSession.accessToken.payload["cognito:groups"]);
        localStorage.setItem("UserGroup", data.signInUserSession.accessToken.payload["cognito:groups"]);
      })
      .catch((error) => {
        console.log('error', error);
        setLoadingUserGroup(true);
        localStorage.setItem("UserGroup", []);
      });
  }, [history]);

  useEffect(() => {
    if (loadingUserGroup && userGroup.length > 0) {
      if (userGroup.includes('web-page')) history.push('/dashboard');
      else if (userGroup.includes('admin')) history.push('/admin');
      else if (['heat-profile', 'coating-record-db', 'dcal-generator'].some(group => userGroup.includes(group))) {
        history.push('/technical');
      }
      else if (userGroup.includes('analysis')) history.push('/log-report');
      else {
        history.push('/web-page');
      }
    }
  }, [loadingUserGroup, userGroup, history]);

  useEffect(() => {
    if (userGroup.length > 0) {
      const newKey = userGroup.includes('web-page') ? '1'
        : userGroup.includes('admin') ? '4'
          : userGroup.some(group => ['heat-profile', 'coating-record-db', 'dcal-generator'].includes(group)) ? '5'
            : userGroup.includes('analysis') ? '2'
              : '3';
      setSelectedKey(newKey);
    }
  }, [userGroup]);

  /** Validating Entered Password with password policy */
  const validateToNextPassword = (e) => {
    const value = e.target.value
    if (!value) {
      setValidateVisible(false);
      setValidatingStatus('');
    }
    if (value) {
      // console.log(value);
      setNewPassword(value)
      setValidatingStatus('validating');
      setTimeout(() => {
        if (value.length > 7 && constants.PASSWORD_PATTERN.test(value)) {
          setValidatingStatus('success');
          if (confirmPassword.length > 7 && confirmPassword === value) {
            setValidatingStatusConfirm('success');
          } else {
            setValidatingStatusConfirm('warning');
          }
          setValidateVisible(false);
        } else {
          setValidatingStatus('warning');
          setValidateVisible(true);
        }
      }, 500)
    }
  };

  /** Checking Entered Password and Confirmation password to match */
  const validateToFirstPassword = (e) => {
    const value = e.target.value

    if (value) {
      // console.log(value)
      setValidatingStatusConfirm('validating');
      setConfirmPassword(value);
      setTimeout(() => {
        if (value.length > 7 && value === newPassword) {
          setValidatingStatusConfirm('success');
        } else {
          setValidatingStatusConfirm('warning');
        }
      }, 500)
    } else {
      setValidatingStatusConfirm('');
    }
  };

  const changeProfileCred = () => {
    changePasswordForm
      .validateFields()
      .then(async (data) => {
        if (validatingStatus == 'success') {
          if (validatingStatusConfirm == 'success') {
            await changeProfilePassword(data.currentPassword, data.newPassword);
            setLoadBtn(true);
            setTimeout(() => {
              setLoadBtn(false);
              changePasswordForm.resetFields();
              setChangePasswordVisible(false);
            }, 1000)
          } else {
            notification.warning({
              message: 'The New password and confirmation password do not match!'
            })
          }
        } else {
          notification.warning({
            message: 'Your password does not match Password policy!'
          })
        }
      });
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} style={{ height: '100vh' }}>
      <div className="d-flex h-100 flex-column">
        <div className="flex-auto logo-container">
          <div className="logo-wrapper pe-3">
            <a href="" className="aside-logo-large">
              <img className="logo-white" src={logoCrc} alt="logo" />
              {/* <img className="logo-black" src={PoweredBy} alt="logo" /> */}
              {/* <Typography.Text className='logo-text'>Pipeline Induction Heat</Typography.Text> */}
            </a>
            <a href="" className="aside-logo-small">
              <img src={logoCrc} alt="logo" />
            </a>
          </div>
          <div className="py-4 user-name d-flex justify-content-center">
            <Tooltip title='Profile' placement='right'>
              <div className="text-center user-logo" onClick={() => setProfileVisible(true)}>
                <Avatar style={{ backgroundColor: '#FFFFFF99' }} size={44}>
                  <span className="icon-18 mr-2">
                    <svg>
                      <use xlinkHref="#user" />
                    </svg>
                  </span>
                </Avatar>
                {/* Long Name */}
                {user && user['custom:first_name'] && user['custom:last_name'] ? <p className="pt-3 full-name"> {user['custom:first_name'] + " " + user['custom:last_name']}</p> :
                  (user && user['custom:first_name'] ? <p className="pt-3 full-name"> {user['custom:first_name']}</p> : '')}
                {/* Short Name */}
                {user && user['custom:first_name'] && user['custom:last_name'] ? <p className="pt-3 first-last-letter"> {user['custom:first_name'].split(' ').shift().charAt(0).toUpperCase() + user['custom:last_name'].split(' ').shift().charAt(0).toUpperCase()} </p> :
                  (user && user['custom:first_name'] ? <p className="pt-3 first-last-letter">{user['custom:first_name'].split(' ').shift().charAt(0).toUpperCase()}</p>
                    : '')}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex-fill  overflow-auto" >
          <Menu
            className="border-0 pb-4 left-menu"
            selectedKeys={[selectedKey]}
            mode="inline"
            onClick={(e) => setSelectedKey(e.key)}
          >
            <>
              {
                userGroup.includes('web-page') &&
                <Menu.Item key="1">
                  <Button
                    type="link"
                    onClick={() => {
                      history.push('/dashboard');
                      setIsShowProjectData(false);
                    }}
                  >
                    <span className="icon-14 me-2">
                      <svg>
                        <use xlinkHref="#dashboard" />
                      </svg>
                    </span>
                    <span className="menu-content"> Dashboard</span>
                  </Button>
                </Menu.Item>
              }
              {
                userGroup && userGroup.length > 0 && userGroup.includes('admin') && (
                  <Menu.Item key="4">
                    {' '}
                    <Button
                      type="link"
                      onClick={() => {
                        history.push('/admin');
                      }}
                    >
                      <span className="icon-14 me-2" style={{ width: '16px', height: '26px' }}>
                        <SettingOutlined style={{ fontSize: '16px' }} />
                      </span>
                      <span className="menu-content"> Admin</span>
                    </Button>
                  </Menu.Item>
                )
              }
              {
                userGroup && userGroup.length > 0 && (userGroup.includes('heat-profile') || userGroup.includes('coating-record-db') || userGroup.includes('dcal-generator')) &&
                (<Menu.Item key="5">
                  {' '}
                  <Button
                    type="link"
                    onClick={() => {
                      history.push('/technical');
                    }}
                  >
                    <span className="icon-14 me-2" style={{ width: '16px', height: '26px' }}>
                      <ApiOutlined style={{ fontSize: '16px' }} />
                    </span>
                    <span className="menu-content"> Technical</span>
                  </Button>
                </Menu.Item>
                )
              }
              {
                userGroup && userGroup.length > 0 && (userGroup.includes('calibration-normal-user'))&&
                (<Menu.Item key="6">
                  {' '}
                  <Button
                    type="link"
                    onClick={() => {
                      history.push('/doc-control');
                    }}
                  >
                    <span className="icon-14 me-2" style={{ width: '16px', height: '26px' }}>
                      <ContainerOutlined style={{ fontSize: '16px' }} />
                    </span>
                    <span className="menu-content"> Doc Control</span>
                  </Button>
                </Menu.Item>
                )
              }
              {userGroup && userGroup.length > 0 && (userGroup.includes('analysis')) &&
                (<Menu.Item key="2">
                  <Button
                    type="link"
                    onClick={() => {
                      history.push('/log-report');
                    }}
                  >
                    <span className="icon-14 me-2">
                      <svg>
                        <use xlinkHref="#analysis" />
                      </svg>
                    </span>
                    <span className="menu-content">Analysis</span>
                  </Button>
                </Menu.Item>)
              }
              <Menu.Item key="3">
                <Button
                  type="link"
                  onClick={() => {
                    history.push('/log-report');
                  }}
                >
                  <span className="icon-14 me-2">
                    <svg>
                      <use xlinkHref="#notifications" />
                    </svg>
                  </span>
                  <span className="menu-content">Notifications</span>
                </Button>
              </Menu.Item>
            </>

          </Menu>
        </div>
        <div className="flex-auto">
          <Menu
            className="border-0 pb-4 left-menu"
            defaultSelectedKeys={['']}
            mode="inline"
          >
            <Menu.Item key="4">
              <Button
                type="link"
                onClick={() => {
                  const element = document.getElementById('body-wrapper');
                  if (sessionStorage.getItem('theme') === 'True') {
                    sessionStorage.setItem('theme', 'False');
                    element.classList.remove('light-theme');
                  } else {
                    sessionStorage.setItem('theme', 'True');
                    element.classList.add('light-theme');
                  }
                  setSwitchtheme(!switchtheme);
                }}
              >
                <span className="icon-14 me-2">
                  <svg>
                    <use xlinkHref="#Theme" />
                  </svg>
                </span>
                <span className="menu-content">Switch Theme</span>
              </Button>
            </Menu.Item>
            <Menu.Item key="5">
              <Button
                type="link"
                onClick={() => {
                  onSignout();
                  // userSignOut();
                  notification.destroy();
                  // clearTokens();
                  history.push('/login');
                  localStorage.clear();
                  sessionStorage.clear();
                  notification.success({
                    duration: 3,
                    message: 'Successfully Logged Out',
                    description: '',
                  });
                }}
              >
                <span className="icon-14 me-2">
                  <svg>
                    <use xlinkHref="#logout" />
                  </svg>
                </span>
                <span className="menu-content"> Logout</span>
              </Button>
            </Menu.Item>
          </Menu>
          <div className="poweredby d-flex justify-content-center" style={{ position: 'relative', right: '18px' }}>
            <div>
              <p className="small mb-1">Pioneered by</p>
              <img src={smartLync} height={40} alt="logo" style={{ position: 'relative', right: '0px' }} />
            </div>
          </div>
        </div>
      </div >
      <Modal
        width="45vw"
        className="pipeline-modal"
        title={`Profile`}
        centered
        visible={profileVisible}
        footer={[
          <Button key="cancel" onClick={() => {
            setProfileVisible(false);
            setChangePasswordVisible(false);
            changePasswordForm.resetFields();
            setValidatingStatus('');
            setValidatingStatusConfirm('');
          }}>
            Close
          </Button>
        ]}
      >
        <div>
          <Row gutter={16}>
            <Col span={6}>
              <UserOutlined className='user_photo' />
            </Col>
            <Col span={18} style={{ display: 'grid' }}>
              <Typography.Text className='profile_text'>{user && user['custom:first_name'] ? user['custom:first_name'] + " " + (user['custom:last_name'] ? user['custom:last_name'] : '') : ''}</Typography.Text>
              <Typography.Text className='profile_text'>{user ? user.email : ''}</Typography.Text><hr />
              <div>
                <Typography.Text className='change_profile_password'
                  onClick={() => setChangePasswordVisible(true)}
                >
                  Change Password
                </Typography.Text>
                {changePasswordVisible &&
                  <Tooltip title="close" placement='right'>
                    <CloseOutlined className='close_password' onClick={() => {
                      setChangePasswordVisible(false);
                      changePasswordForm.resetFields();
                      setValidateVisible(false);
                      setValidatingStatus('');
                      setValidatingStatusConfirm('')
                    }} />
                  </Tooltip>}
              </div>

              <Row className='profilePassowrd_form'>
                {changePasswordVisible &&
                  <Col span={24}>
                    <Form
                      className='forgotPswdForm'
                      form={changePasswordForm}
                      name="normal_login"
                      initialValues={{
                        remember: true,
                      }}
                    >
                      <Form.Item
                        name="currentPassword"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your current password!',
                          }
                        ]}
                      >
                        <Input.Password size="large" type="password" placeholder="Current Password"
                          iconRender={visible => (visible ? <EyeOutlined className='password_icon' /> : <EyeInvisibleOutlined className='password_icon' />)}
                        />
                      </Form.Item>
                      <Popover placement="bottomRight" title={constants.TITLE} content={passwordPolicy} trigger="focus" visible={validateVisible}>
                        <Form.Item
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your new password!',
                            }
                          ]}
                          hasFeedback
                          validateStatus={validatingStatus}
                        >
                          <Input.Password size="large" type="password" placeholder="New Password"
                            onChange={validateToNextPassword}
                            iconRender={visible => (visible ? <EyeOutlined className='password_icon' /> : <EyeInvisibleOutlined className='password_icon' />)}
                          />
                        </Form.Item>
                      </Popover>
                      <Form.Item
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your confirm password!',
                          }
                        ]}
                        hasFeedback
                        validateStatus={validatingStatusConfirm}
                      >
                        <Input.Password size="large" type="password" placeholder="Confirm New Password"
                          onChange={validateToFirstPassword}
                          iconRender={visible => (visible ? <EyeOutlined className='password_icon' /> : <EyeInvisibleOutlined className='password_icon' />)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          size="large"
                          shape="round"
                          block
                          htmlType="submit"
                          className="profile_password_button"
                          onClick={changeProfileCred}
                          loading={loadBtn}
                        >
                          Change Password
                        </Button>
                      </Form.Item>

                    </Form>
                  </Col>
                }
              </Row>
            </Col>

          </Row>

        </div>
      </Modal>

    </Sider >
  );
};

Sidebar.propTypes = {
  onSignout: func.isRequired,
};

const mapDispatchToProps = {
  onSignout: signOut,
};

export default connect(null, mapDispatchToProps)(Sidebar);