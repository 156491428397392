import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchTestTypesSuccess,
    fetchTestTypesFailure,
    addTestFailure,
    addTestSuccess,
    fetchStationTestDetailsSuccess,
    fetchStationTestDetailsFailue,
    uploadITPAddstationFileSuccess,
    uploadITPAddstationFileFileFailure,
    fetchStationNameSuccess,
    fetchStationNameFailure
} from './action';
import { getProjectStationDetails } from '../stationDetails/action';
import {
    FETCH_TEST_TYPES, ADD_TEST_DETAILS, FETCH_STATION_TEST_DETAILS, DELETE_TEST_DETAILS, UPDATE_TEST_DETAILS, UPLOAD_ITPADDSTATION_FILE, FETCH_STATION_NAME
} from './types';
import axiosConfig from './axiosConfig';

/** Get all Test Value Types */
function* fetchTestTypes() {
    try {
        const res = yield axiosConfig.get('/test-value-types');
        const response = get(res, ['data', 'data'], {});
        // notification.destroy();
        if (res.status === 200) {
            yield put(fetchTestTypesSuccess({ response }));
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchTestTypesFailure());
        const errorMessage = JSON.parse(JSON.stringify(error.error));
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Test Value Types.',
            description: `${errorMessage}`,
        });
    }
}

/** Get all  Station Names */
function* fetchStationName(queryString) {
    try {
        const res = yield axiosConfig.get(`/pqt-sow-station?${queryString.queryString}`);
        const response = get(res, ['data', 'data'], {});
        // notification.destroy();
        if (res.status === 200) {
            yield put(fetchStationNameSuccess({ response }));
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchStationNameFailure());
        const errorMessage = JSON.parse(JSON.stringify(error.error));
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the station names.',
            description: `${errorMessage}`,
        });
    }
}

function* addTestDetails({
    data, sowId
}) {
    try {
        console.log(data,sowId)
        const res = yield axiosConfig.post(`/pqt-test`, data);
       // const response = get(res, ['data', 'data'], {});
        notification.destroy();
        console.log(res)
        if (res.status === 200) {
            // yield put(addTestSuccess({ response }));
            // var id = sessionStorage.getItem('activeId');
            var id = sowId;
            // yield put(getProjectStationDetails(parseInt(id)));
            notification.success({
                message: `Added Test Successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addTestFailure());
        //const errorMessage = JSON.parse(JSON.stringify(error.error));
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the station.',
           // description: `${errorMessage}`,
        });
    }
}

/** Get Project Station Test Details */
function* fetchStationTestDetails({ id }) {
    try {
        const res = yield axiosConfig.get(`/pqt-test?sowStationId=${id}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put(fetchStationTestDetailsSuccess({ response }));
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchStationTestDetailsFailue());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the project details.',
            description: `${error}`,
        });
    }
}

/** Function to delete Station Test Details (Inspection Test) */
function* deleteTestDetails({
    testId, sowId
  }) {
    try {
        const res = yield axiosConfig.delete(`/pqt-test/${testId}`);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        // var id = sessionStorage.getItem('activeId');
        yield put(getProjectStationDetails(parseInt(sowId)));
        notification.success({
          message: 'Test Details Deleted Successfully.',
        });
      } else {
        notification.error({
          message: 'Something went wrong.',
        });
      }
    } catch (error) {
      notification.destroy();
      notification.error({
        message: 'Test Details Deletion Failed.',
        description: `${error}`,
      });
    }
  }


  function* updateTestDetails({
    data, testId, sowId
}) {
    try {
        const res = yield axiosConfig.put(`/pqt-test/${testId}`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            var id = sowId;
            yield put(getProjectStationDetails(parseInt(id)));
            notification.success({
                message: `Updated ${data.typeOfTest} Test Successfully.`,
            });
        } else {
            notification.error({
                message: `Unable to update ${data.typeOfTest}. Please try again later.`,
            });
        }
    } catch (error) {
        // yield put(addTestFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the station.',
            description: `${error}`,
        });
    }
}

//itp bulk upload
function* uploadITPAddstationFile({ data, projectId, sowId }) {
    try {
        const obj = { data, projectId };

        const invokeRes = yield axiosConfig.post(`/pqt-tests/bulk-upload`, obj);

        if (invokeRes.status === 200) {
            var id = sowId;
            yield put(getProjectStationDetails(parseInt(id)));
            notification.success({ message: 'File successfully uploaded.' });
        } else {
            notification.error({ message: 'File uploading failed. Please try again.' });
        }
    } catch (error) {
        yield put(uploadITPAddstationFileFileFailure());
        if (error.error) {
            const errorMessage = JSON.parse(JSON.stringify(error.error));
            notification.error({ message: `Error: ${errorMessage}` });
        } else {
            notification.error({ message: 'Please select a valid File.' });
        }
    }
}


function* testPQTWatcher() {
    yield takeLatest(FETCH_TEST_TYPES, fetchTestTypes);
    yield takeLatest(FETCH_STATION_NAME, fetchStationName);
    yield takeLatest(ADD_TEST_DETAILS, addTestDetails);
    yield takeLatest(FETCH_STATION_TEST_DETAILS, fetchStationTestDetails);
    yield takeLatest(DELETE_TEST_DETAILS, deleteTestDetails);
    yield takeLatest(UPDATE_TEST_DETAILS, updateTestDetails);
    yield takeLatest(UPLOAD_ITPADDSTATION_FILE, uploadITPAddstationFile);
}

function* testPQTSagas() {
    yield all([
        call(testPQTWatcher),
    ]);
}

export default testPQTSagas;


export const AddTestDetails = async (
    data, sowId
) => {
    try {
        console.log(data, sowId)
        const res = await axiosConfig.post(`/pqt-test`, data);
        // const response = get(res, ['data', 'data'], {});
        notification.destroy();
        console.log(res)
        if (res.status === 200) {
            // yield put(addTestSuccess({ response }));
            // var id = sessionStorage.getItem('activeId');
            var id = sowId;
            // yield put(getProjectStationDetails(parseInt(id)));
            notification.success({
                message: `Added Test Successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {

        // yield put(addTestFailure());
        //const errorMessage = JSON.parse(JSON.stringify(error.error));
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the station.',
            // description: `${errorMessage}`,
        });
    }
}


//itp bulk upload
export async function OnUploadPQTTestFileData(data, sowId) {
    try {
        const obj = { data, sowId };

        const invokeRes = await axiosConfig.post(`/pqt-test/bulk-upload`, obj);

        if (invokeRes.status === 200) {
            var id = sowId;

            // yield put(getProjectStationDetails(parseInt(id)));
            notification.success({ message: 'File successfully uploaded.' });
        } else {
            notification.error({ message: 'File uploading failed. Please try again.' });
        }
    } catch (error) {
        // yield put(uploadITPAddstationFileFileFailure());
        if (error.error) {
            const errorMessage = JSON.parse(JSON.stringify(error.error));
            notification.error({ message: `Error: ${errorMessage}` });
        } else {
            notification.error({ message: 'Please select a valid File.' });
        }
    }
}