import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
  fetchStationDetailsSuccess,
  fetchStationDetailsFailure,
  getProjectStationDetailsSuccess,
  getProjectStationDetailsFailure,
  addProjectStationDetailsFailure,
  addStationSuccess,
  addStationFailure,
  deleteStationListProcess,
  deleteStationListSuccess,
  copyStationSuccess,
  copyStationFailure
} from './action';
import {
  ADD_STATION_DETAILS,
  FETCH_STATIONS, GET_STATION_DETAILS, UPDATE_STATION_NAME, DELETE_SOW_STATION, ADD_STATION, DELETE_STATION_LIST, COPY_STATION_DETAILS
} from './types';
import axiosConfig from './axiosConfig';

/** Get all stations */
function* fetchStationDetails() {
  try {
    const res = yield axiosConfig.get('/pqt-sow-stations');
    const response = get(res, ['data', 'data'], {});
    // notification.destroy();
    if (res.status === 200) {
      yield put(fetchStationDetailsSuccess({ response }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchStationDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the project details.',
      description: `${error.error}`,
    });
  }
}

/** Get Project Station Details */
function* getProjectStationDetails({ id }) {
  try {
    const res = yield axiosConfig.get(`/pqt-sow-station/${id}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(getProjectStationDetailsSuccess({ response }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(getProjectStationDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the project details.',
      description: `${error.error}`,
    });
  }
}

function* addProjectStationDetails({data, name}) {
  try {
    console.log('SagaName',data,name)
    const res = yield axiosConfig.post(`/pqt-sow-station`, data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      //var id = data.statmentOfWorkId;
      yield getProjectStationDetails({ id: id });
      notification.success({
        message: `${name} Added Successfully.`,
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(addProjectStationDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while adding the station.',
      description: `${error.error}`,
    });
  }
}

function* updateSowStationName({
  data, stationId, sowId
}) {
  try {
    const res = yield axiosConfig.put(`/pqt-sow-station/${stationId}`, data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield getProjectStationDetails({ id: sowId });
      notification.success({
        message: `${data.name} Updated Successfully.`,
      });
    } else {
      notification.error({
        message: `Unable to update ${data.name}. Please try again later.`,
      });
    }
  } catch (error) {
    yield put(addProjectStationDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'Added Station Name already exist!',
      description: `${error.error}`,
    });
  }
}


/** Function to delete Station (Inspection Test) */
function* deleteSowStation({
  sowStationId, sowId, name
}) {

  try {
    const res = yield axiosConfig.delete(`/pqt-sow-station/${sowStationId}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield getProjectStationDetails({ id: sowId });
      notification.success({
        message: `${name} deleted Successfully.`,
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(addProjectStationDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'Test Details Deletion Failed.',
      description: `${error.error}`,
    });
  }
}

/** Function to add new station name */
function* addStation({
  payload
}) {
  try {
    const res = yield axiosConfig.post('/pqt-sow-station', payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(addStationSuccess({ response }));
      notification.success({
        message: `${payload.name} Added Successfully.`,
      });
    } else {
      notification.warn({
        message: `Unable to add ${payload.name}. Please try again later `,
      });
    }
  } catch (error) {
    yield put(addStationFailure());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'Added Station Name already exist!'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the Station. Please try again.',
        description: `${error.error}`,
      });
    }
  }
}

//function to copy station from one to another
function* copyStation({
  payload
}) {
  console.log("payload", payload)
  try {
    const res = yield axiosConfig.put('/copy-sow-tests', payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(copyStationSuccess({ response }));
      notification.success({
        message: `Test copied successfully.`,
      });
    } else {
      notification.warn({
        message: `Unable to copy. Please try again later `,
      });
    }
  } catch (error) {
    yield put(copyStationFailure());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'Unable to copy. Please try again later !'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the Station. Please try again.',
        description: `${error.error}`,
      });
    }
  }
}


/** Function to delete Station List (Inspection Test) */
function* deleteStationList({
  data
}) {

  try {
    // yield put(deleteStationListProcess());
    const res = yield axiosConfig.delete(`/station/${data.id}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      var sowId = sessionStorage.getItem('sowId');
      yield getProjectStationDetails({ id: parseInt(sowId) });
      yield fetchStationDetails();
      yield put(deleteStationListSuccess());
      notification.success({
        message: `${data.name} deleted Successfully.`,
      });
    } else {
      notification.error({
        message: 'Unable to Delete. Please try again!',
      });
    }
  } catch (error) {
    yield put(fetchStationDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'Station List Deletion Failed.',
      // description: `${error}`,
    });
  }
}





function* sowPQTWatcher() {
  yield takeLatest(FETCH_STATIONS, fetchStationDetails);
  yield takeLatest(GET_STATION_DETAILS, getProjectStationDetails);
  yield takeLatest(ADD_STATION_DETAILS, addProjectStationDetails);
  yield takeLatest(UPDATE_STATION_NAME, updateSowStationName);
  yield takeLatest(DELETE_SOW_STATION, deleteSowStation);
  yield takeLatest(ADD_STATION, addStation);
  yield takeLatest(DELETE_STATION_LIST, deleteStationList);
  yield takeLatest(COPY_STATION_DETAILS, copyStation);

}

function* stationPQTSagas() {
  yield all([
    call(sowPQTWatcher),
  ]);
}

export default stationPQTSagas;


export const AddStationDetails = async (data, name) => {
  try {
    console.log('SagaName', data, name)
    const res = await axiosConfig.post(`/pqt-sow-station`, data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      var id = data.statmentOfWorkId;
      await getProjectStationDetails({ id: id });
      notification.success({
        message: `${name} Added Successfully.`,
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    await put(addProjectStationDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while adding the station.',
      description: `${error.error}`,
    });
  }
}