/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import {
  LikeFilled, DislikeFilled, CloseOutlined, HourglassOutlined, HourglassFilled, ArrowLeftOutlined,
  TableOutlined
} from '@ant-design/icons';
import { Calendar, Badge, Typography, Form, Select, Spin, Modal, Button, Row, Col, DatePicker, notification, Tooltip, Table } from 'antd';
import moment from 'moment';
import LogDetailsTable from './LogDetailsTable';
import CalendarView from './Calender';
import { OnFetchCalenderDetails, OnFetchLogDetails } from '../../redux/PQTlogDetails/saga';

const { Text } = Typography;
const { Option } = Select;

const LogCalender = (props) => {
  const { locationData, dataList, columnList, showLogLoader,
    setPipeline,
    pipeline,

    setSelectedPipeline,
    selectedPipeline,
    calenderForm,
    getCalData,

    isCalenderLoading,

    onFetchLogDetails,
    setDataList,
    totalCount,
    calenderView, setCalenderView,
    calenderClass, setCalenderClass, selectedSOW

  } = props;

  const [loading, setLoading] = useState(false);
  const [logPopup, setLogPopup] = useState(false);
  const [tableDated, settableDated] = useState(false);
  // const [pipeline, setPipeline] = useState('');
  // const [selectedPipeline, setSelectedPipeline] = useState('');
  const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('year').format('YYYY-MM-DD'));
  const [yearData, setYearData] = useState(moment().format('YYYY'));
  const [monthData, setMonthData] = useState(moment().format('MM'));
  const [mode, setMode] = useState(moment().format('MM'));
  const [selectedDate, setSelectedDate] = useState('');
  // const [calenderList, setCalenderList] = useState([{
  //   "date": "2025-03-14",
  //   "totalWelds": 2
  // },
  //   {
  //     "date": "2025-03-15",
  //     "totalWelds": 3
  //   },
  //   {
  //     "date": "2025-03-17",
  //     "totalWelds": 5
  //   }]);

  const [calenderList, setCalenderList] = useState([])
  const [isResultModalVisible, setIsResultModalVisible] = useState(false);

  const [selectDataSource, setSelectDataSource] = useState([]);
  const [selectColumnSource, setSelectColumnSource] = useState([]);
  const [dataResultTable, setDataResultTable] = useState([]);

  const [transformDatas, setTransformDatas] = useState([]);
  const [columnData, setColumnData] = useState([]);



  useEffect(() => {
    if (pipeline && calenderList && calenderList.length > 0) {
      setCalenderView(true);
      setCalenderClass('view')
    } else {
      setCalenderView(true);
      setCalenderClass('view')
    }
  }, [calenderList]);


  /**Inner function for dateCellRender */
  const getListData = (value) => {
    let listData = [];
    switch (moment(value).format('YYYY-MM-DD')) {
      case moment(value).format('YYYY-MM-DD'):
        for (let i = 0; i < calenderList.length; i++) {
          if (calenderList[i].date == moment(value).format('YYYY-MM-DD')) {
            listData.push(calenderList[i])
          }
        }
        break;

      default:
    }

    return listData || [];
  };


  /**Inner function for monthCellRender */
  const getMonthData = (value) => {
    let listData = [];
    let num = 0;
    switch (moment(value).format('YYYY-MM')) {
      case moment(value).format('YYYY-MM'):
        for (let i = 0; i < calenderList.length; i++) {
          if (moment(calenderList[i].date).format('YYYY-MM') == moment(value).format('YYYY-MM')) {
            var obj = {
              date: moment(calenderList[i].date).format('YYYY-MM'),
              totalWelds: calenderList[i].totalWelds
            }
            listData.push(obj);
          }
        }
        if (listData.length > 0) {
          for (var i = 0; i < listData.length; i++) {
            num += listData[i].totalWelds
          }
        }

        break;

      default:
    }
    return num;
  }

  /** Function to render Monthly weld count (For each month) */
  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month" style={{ background: num <= 100 ? 'green' : (num <= 200 ? 'orange' : '') }} >
        <section style={{ textAlign: 'center' }}>Welds <div style={{ textAlign: 'center' }}>{num}</div></section>
      </div >
    ) : null;
  };

  /**Function to render dated welds count */
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <>
        {listData.map((item) => (
          <div onClick={e => handleLogChange(item)} className="events" key={item.totalWelds} style={{ background: item.totalWelds <= 100 ? 'green' : (item.totalWelds <= 200 ? 'orange' : '') }}>
            <section style={{ textAlign: 'center' }}>Welds<div style={{ textAlign: 'center' }}>{item.totalWelds}</div> </section>
          </div>
        ))}
      </>
    );
  };


  /** On selection of sow (pipeline) */
  const handlePipelineChange = (value) => {
    setPipeline(value);
    if (locationData.length > 0) {
      const locData = locationData.find(u => u.pipelineSection === value);
      setSelectedPipeline(locData);
    }
  }



  const handleCancel = () => {
    setLogPopup(false);
    setDataList([]);
  }

  const handleCalederChange = (value, mode) => {
    // console.log('handleCalederChange', value.format('YYYY-MM-DD'), mode);
  }



  const onPanelChange = async (value, mode) => {
    //console.log("Selected:", value.format('YYYY-MM-DD'), "Mode:", mode);
    setSelectedDate(value.format('YYYY-MM-DD'))
    setMode(mode)
    setLoading(true)
    // Update the selected month & year
    const selectedYear = value.year();
    const selectedMonth = value.format('MM'); // Ensures two-digit format


    // Create request payload for API call
    const obj = {
      sowId: selectedSOW.id,  // Ensure `selectedSOW` is defined in state
      month: selectedMonth,   // Selected month in "MM" format
      year: selectedYear      // Selected year as number
    };


    try {
      const res = await OnFetchCalenderDetails(obj);
      //console.log("Fetched Data:", res);



      if (res && res.length > 0) {
        await setCalenderList(res);  // Update state with fetched data

      setMonthData(selectedMonth)
      setYearData(selectedYear)


      if (mode === 'year') {
        const mothsta = moment(`${selectedYear}-${selectedMonth}-01`).startOf('month');  // First day of selected month
        const end = moment(`${selectedYear}-${selectedMonth}-31`).endOf('month');  // Last day of selected month




        const logObj = {
          fromDate: mothsta.format('YYYY-MM-DD'),
          toDate: end.format('YYYY-MM-DD'),
          sowId: selectedSOW.id,
          pageSize: 1000,
          pageIndex: 0
        };

        const res1 = await OnFetchLogDetails(logObj);
        console.log("Results Log Details Response:", res1?.dataSource[0]);
        if (res1?.dataSource[0] && res1?.dataSource[0].length > 0)
          setSelectDataSource(res1?.dataSource[0]);
        else
          setSelectDataSource([]);
      }
      else {
        const logObj = {
          fromDate: selectedDate,
          toDate: selectedDate,
          sowId: selectedSOW.id,
          pageSize: 1000,
          pageIndex: 0
        };

        // console.log("Fetching log details for:", logObj);
        const res1 = await OnFetchLogDetails(logObj);
        // console.log("Log Details Response:", res1);

        // console.log(" Results Log Details Response:", res1?.dataSource[0]);
        if (res1?.dataSource[0] && res1?.dataSource[0].length > 0)
          setSelectDataSource(res1?.dataSource[0]);
        else
          setSelectDataSource([]);
      }

      } else {
        setCalenderList([]);  // Handle empty response
        setSelectDataSource([]);
      }

      setLoading(false)
    } catch (error) {
      console.error("Error fetching calendar details:", error);
    }
  };
  const handleLogChange = async (item) => {
    if (item) {
      //const projectId = get(selectedProject, 'id', undefined);

      setLoading(true)

      if (mode === 'year') {
        const mothsta = moment(`${selectedYear}-${selectedMonth}-01`).startOf('month');  // First day of selected month
        const end = moment(`${selectedYear}-${selectedMonth}-31`).endOf('month');  // Last day of selected month

        const logObj = {
          fromDate: mothsta.format('YYYY-MM-DD'),
          toDate: end.format('YYYY-MM-DD'),
          sowId: selectedSOW.id,
          pageSize: 1000,
          pageIndex: 0
        };

        // console.log("Fetching log details for:", logObj);

        const res = await OnFetchLogDetails(logObj); // Fetch data based on the selected date range
        //console.log("Fetching log details for:", res);
        if (res?.dataSource[0] && res?.dataSource[0].length > 0) {
          setSelectDataSource(res?.dataSource[0]);
          //  setSelectColumnSource(res.colunmns)
        }
        else
          setSelectDataSource([]);
      }
      else {
        var obj = {
          fromDate: item.date,
          toDate: item.date,
          sowId: selectedSOW.id,
          pageSize: 1000,
          pageIndex: 0
        }
        setSelectedDate(item.date)
        // console.log("Fetching log details for:", obj);

        const res = await OnFetchLogDetails(obj); // Fetch data based on the selected date range
        // console.log("Fetching log details for:", res);
        if (res && res.dataSource && res.dataSource[0].length > 0) {
          setSelectDataSource(res?.dataSource[0]);
          //  setSelectColumnSource(res.colunmns)
        }
        else
          setSelectDataSource([])
      }
      setLoading(false)
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      if (!selectedSOW || !selectedSOW.id) return; // Ensure selectedSOW is available

      setLoading(true); // Start loading

      const obj = {
        sowId: selectedSOW.id,
        month: monthData,
        year: yearData
      };

      try {
        const res = await OnFetchCalenderDetails(obj);


        if (res && res.length > 0) {
          setCalenderList(res);
          const logObj = {
            fromDate: (moment().format('YYYY-MM-DD')),
            toDate: (moment().format('YYYY-MM-DD')),
            sowId: selectedSOW.id,
            pageSize: 1000,
            pageIndex: 0
          };


          const res1 = await OnFetchLogDetails(logObj);
          //console.log("Log Details Response:", res1);


          if (res1?.dataSource[0] && res1?.dataSource[0].length > 0)
            setSelectDataSource(res1?.dataSource[0]);
          // setSelectDataSource(data || []);
        } else {
          setSelectDataSource([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [selectedSOW]);

  const transformData = () => {
    let transformedRows = {};

    // Extract unique weld IDs
    const weldIds = [...new Set(selectDataSource.map((item) => item.weld_id))];

    const getUniString = (item) => {
      if (!item.value) return "-";

      const values = item.value.split(","); // Convert to array
      const unit = item.test_value_type?.display_str || ""; // Get the unit

      return values.length > 1
        ? `${values.join(" / ")} ${unit}`.replace(/\[|\]/g, "")  // Remove brackets
        : `${values[0]} ${unit}`.replace(/\[|\]/g, "");
    };

    // Transform data while merging duplicate process descriptions
    selectDataSource
      .filter((item) => item.process_description !== "PROCESS DESCRIPTION")
      .forEach((item) => {
        const processKey = item.process_description;

        // If process already exists, update it; otherwise, create a new entry
        if (!transformedRows[processKey]) {
          transformedRows[processKey] = {
            key: processKey,
            process_description: processKey,
          };

          // Initialize all weld_id columns to "-"
          weldIds.forEach((weldId) => {
            transformedRows[processKey][weldId] = "-";
          });
        }

        // Update the correct weld_id column
        transformedRows[processKey][item.weld_id] =
          item.type_of_test === "Table"
          ? item.value && item.value.trim() !== ""
            ? <TableOutlined onClick={() => { setDataResultTable(item); setIsResultModalVisible(true); }} type="primary">Table</TableOutlined>
            : "-" // If empty, set to "-"
            : item.type_of_test === "Value"
              ? getUniString(item) // Format value properly
              : item.value || "-";
      });

    // Convert object back to array
    let finalRows = Object.values(transformedRows);

    // Append last rows (repair_status & overall_status)
    ["repair_status", "overall_status"].forEach((param) => {
      let row = { key: param, process_description: param.replace(/_/g, " ").toUpperCase() };

      weldIds.forEach((weldId) => {
        const record = selectDataSource.find((item) => item.weld_id === weldId);
        row[weldId] = record ? record[param] : "-";
      });

      finalRows.push(row);
    });

    return finalRows;
  };



  const getColumnData = () => {
    // Extract unique weld IDs
    const weldIds = [...new Set(selectDataSource.map((item) => item.weld_id))];

    // Define dynamic columns
    const columns = [
      {
        title: "Process Description",
        dataIndex: "process_description",
        key: "process_description",
        width: 200,
        fixed: true
      },
      ...weldIds.map((weldId) => ({
        title: weldId,
        dataIndex: weldId,
        key: weldId,
        align: "center",
        width: 120,
        render: (text) =>
          text === "accept" ? (
            <Tooltip title="Accept">
              <LikeFilled style={{ color: "#7eff12", background: "rgba(0, 0, 0, 0.58)", padding: "5px", borderRadius: "25px", border: "1px solid #fff", width: "24px", height: "24px" }} />
            </Tooltip>
          ) : text === "reject" ? (
              <Tooltip title="Reject">
              <DislikeFilled style={{ color: "#ff0000", background: "rgba(0, 0, 0, 0.58)", padding: "5px", borderRadius: "25px", border: "1px solid #fff", width: "24px", height: "24px" }} />

              </Tooltip>
            ) : text === "pending" ? (
              <Tooltip title="Pending">
                  <HourglassOutlined style={{ color: "#ffa500", background: "rgba(0, 0, 0, 0.58)", padding: "5px", borderRadius: "25px", border: "1px solid #fff", width: "24px", height: "24px" }} />
                </Tooltip>
          ) : (
            text
          ),
      })),
    ];

    return columns;
  };


  const getTableColumns = (item) => {
    if (!item || !item.value) return []; // Handle empty item case

    const unit = item.test_value_type?.display_str || ""; // Extract unit
    let columns = [
      {
        title: `${unit}`, // First column is the unit
        dataIndex: "name",
        key: "name",
      },
    ];

    try {
      const parsedData = JSON.parse(item.value); // Parse JSON safely

      if (!parsedData.columns || !Array.isArray(parsedData.columns)) return columns; // Ensure columns exist

      parsedData.columns.forEach((col, index) => {
        columns.push({
          title: col, // Column title from JSON
          dataIndex: `col_${index}`, // Unique data key
          key: `col_${index}`,
          align: "center",
        });
      });
    } catch (error) {
      console.error("Error parsing JSON in getTableColumns:", error);
    }

    return columns;
  };

  const getTableData = (item) => {
    if (!item || !item.value) return [];

    try {
      const parsedData = JSON.parse(item.value); // Safely parse JSON
      if (!parsedData.rows || !Array.isArray(parsedData.rows)) return []; // Ensure rows exist

      return parsedData.rows.map((row, rowIndex) => {
        let rowData = { key: rowIndex, name: row.name };

        row.values.forEach((value, index) => {
          rowData[`col_${index}`] = value; // Assign values dynamically
        });

        return rowData;
      });
    } catch (error) {
      console.error("Error parsing JSON in getTableData:", error);
      return [];
    }
  };

  return (
    <div>
      <Row gutter={16}>
        {/* Calendar Section */}
        <Col span={8}>
          <div className={`PQT-log-calender`}>
            <Calendar
              dateCellRender={dateCellRender}
              monthCellRender={monthCellRender}
              onSelect={handleCalederChange}
              onPanelChange={onPanelChange}
              style={{ height: '200px' }} // Adjust dimensions here
            // fullscreen={false} // Removes full-screen mode
            />
          </div>
        </Col>

        {/* Table Section (Displays when a date is selected) */}
        <Col span={16} style={{ overflowX: "auto" }}>
          {loading ? (
            <Spin size="large" />
          ) : selectDataSource && selectDataSource.length > 0 ? (
            <div className="addPQTDetails">
              <Table
                  columns={getColumnData()}
                  dataSource={transformData()}
                  pagination={false}
                  width="100%"
                  className="h-100 pipeline-table equipPaginationTable log-table logDetails_table  PQT_logDetails_table table-fixed-column"
                  // scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
                />
            </div>
          ) : (
            <Table loading={loading} />
          )}
        </Col>

      </Row>

      <Modal
        width="50vw"
        className="pipeline-modal"
        title="Table Results"
        centered
        visible={isResultModalVisible}
        footer={[
          <Button key="cancel" onClick={() => { setIsResultModalVisible(false) }}>
            Cancel
          </Button>,

        ]}
      >
        <div>
          <Table
            columns={getTableColumns(dataResultTable)}
            dataSource={getTableData(dataResultTable)}
            width={'100%'}
            pagination={false}
            className="h-100 pipeline-table equipPaginationTable"
          />
        </div>
      </Modal>


    </div>
  )
};

export default LogCalender;