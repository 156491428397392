/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Row, Table, Col, Typography, Modal, Form,
  notification, Select, Tooltip, InputNumber, Button, Checkbox, Skeleton, Input, Radio
} from 'antd';
import { connect } from 'react-redux';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined
} from '@ant-design/icons';
import AddNewShipments from './AddNewShipments';
import ShipmentLogs from './ShipmentLogs';




const { Title, Text } = Typography;
const { Option } = Select;

const ShipmentDashBoardPage = (props) => {
  const {
    selectedProject,

  } = props;


  const [projectIdValue, setProjectIdValue] = useState(false);
  const [equipmentFormData] = Form.useForm();

  const [showAddModel, setShowAddModel] = useState(false);
  const [isTableModelVisible, setIsTableModelVisible] = useState(false)
  const [equipmentData, setEquipmentData] = useState([
    {
      sno: 1,
      Shipment_Type: 'Blasting',
      Shipment_Number: 'BB/100B',
      usageStatus: 1
    },
    {
      sno: 2,
      Shipment_Type: 'Induction Heating',
      Shipment_Number: 'AA/100B',
      usageStatus: 1
    }


  ]);






  const isWriteAccess = true //isButtonDisabled();

  /** Setting coloumn (headers) for Equipment table */
  const columns = [
    {
      // title: 'PIH Equipment',
      dataIndex: 'sno',
      key: 'sno',
      align: 'left',
      width: '100px',
    },
    {
      title: 'Shipment Type',
      dataIndex: 'Shipment_Type',
      key: 'Shipment_Type',
      // align: 'left',
      ellipsis: {
        showTitle: false
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      )
    },

    {
      title: 'Shipment Number',
      dataIndex: 'Shipment_Number',
      key: 'Shipment_Number',
      align: 'center',
      ellipsis: {
        showTitle: false
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      key: 'action',
      dataIndex: 'action',
      // fixed: 'right',
      width: '100px',
      render: (text, record) => {
        return (
          <div>
            <Tooltip title={(record.usageStatus === 1 ? "In Use" : (record.usageStatus === 2 ? 'In Bakup' : ''))} >
              <span className="icon-18 mr-2 cursor-pointer edit-hover"  >
                <span className='blinkingDot' style={{ background: (record.usageStatus === 1 ? 'limegreen' : (record.usageStatus === 2 ? 'orange' : 'none')) }}>
                  <span className='blinking' style={{ background: (record.usageStatus === 1 ? 'limegreen' : (record.usageStatus === 2 ? 'orange' : 'none')) }}></span>
                </span>
              </span>
            </Tooltip>
          </div>
        );
      },
      align: 'center'
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
      // fixed: 'right',
      width: '200px',
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="View Shipments" >
              <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                <EyeOutlined style={{ fontSize: '18px' }}
                  onClick={() => { handleEyeClick(record) }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Update">
              <span className="icon-18 mr-2 cursor-pointer edit-hover"
              // style={{ cursor: 'not-allowed' }}
              >
                <EditOutlined style={{ fontSize: '18px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }}
                // onClick={() => { !isWriteAccess ? showEditModal(record) : undefined }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                <DeleteOutlined style={{
                  fontSize: '18px',
                  color: isWriteAccess ? 'gray' : '',
                  opacity: isWriteAccess ? '0.7' : '',
                }} //onClick={() => { !isWriteAccess ? showDeleteModal(record) : undefined }}
                />
              </span>
            </Tooltip>
          </div>
        );
      },
      align: 'center'
    },
  ];


  /** Close Modal on close of table details */
  const handleCancelTable = () => {
    setIsTableModelVisible(false);

  }
  /**Open checks table / Modal on view of checks (double click on row disabled)  */
  const handleEyeClick = async (record) => {

    setIsTableModelVisible(true);

    // await onGetCheckIntervals();
  }


  return (
    <div className="h-100 overflow-y-hidden overflow-x-hidden equipmentsPage">
      <div className="flex-auto">
        <div className="d-flex mb-3 align-items-center tab-content-title">
          <Title className="mb-0" level={4}>
            List of Shipments
          </Title>
          <div className='searchEquip'>
            <Input
              placeholder='Search Shipments'
              // onChange={onSearchEquip}
              // value={searchKeyExist}
              allowClear
            />
          </div>

          <div className='inspection-logs'>
            <div className="new-project-team">
              <Text onClick={() => { setShowAddModel(true) }} className="px-3 add-new-team" >
                <span className="icon-22">
                  <svg>
                    <use xlinkHref="#add" />
                  </svg>
                </span>
                Add New
              </Text>
            </div>

          </div>
        </div>
        <div className="custom-line" />
      </div>
      <div className='addTeamDetails'>
        <Table

          columns={columns}
          dataSource={[...equipmentData]}
          className="h-100 pipeline-table equip_table equipPaginationTable"
          scroll={{ y: 'calc(100vh - 300px)' }}




        // loading={isGetEquipDetailsLoading}
        />
        <div className="new-team">

          {/* <Text className='equip_note'>Double Click on table row to display Equipment Logs.</Text> */}
        </div>
      </div>


      {showAddModel && <AddNewShipments

        showAddModel={showAddModel}
        setShowAddModel={setShowAddModel}
      ></AddNewShipments>}


      <Modal
        className="equipTable-modal"
        title={`Shipment Log`}
        centered
        visible={isTableModelVisible}
        footer={[
          <Button key="cancel" onClick={handleCancelTable}>
            Close
          </Button>,

        ]}
      >
        <ShipmentLogs>

        </ShipmentLogs>
      </Modal>


    </div >
  );
}

const mapStateToProps = ({ }) => {

  return {


  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDashBoardPage);