// ITP Section (Inspection Test)
export const FETCH_STATIONS = '@@/PQTlist-stations';
export const SET_STATIONS = '@@userPQT/set-stations';
export const FETCH_STATIONS_FAILURE = '@@userPQT/set-station-failure';


export const GET_STATION_DETAILS = '@@sowPQT/get-station-details';
export const GET_STATION_DETAILS_SUCCESS = '@@sowPQT/get-station-details-success';
export const GET_STATION_DETAILS_FAILURE = '@@sow/get-station-details-failure';


export const ADD_STATION_DETAILS = '@@sowPQT/add-station-details';
export const ADD_STATION_DETAILS_SUCCESS = '@@sowPQT/add-station-details-success';
export const ADD_STATION_DETAILS_FAILURE = '@@sowPQT/add-station-details-failure';

//copy Test
export const COPY_STATION_DETAILS = '@@sowPQT/copy-station-details';
export const COPY_STATION_DETAILS_SUCCESS = '@@sowPQT/copy-station-details-success';
export const COPY_STATION_DETAILS_FAILURE = '@@sowPQT/copy-station-details-failure';

export const UPDATE_STATION_NAME = '@@sowPQT/update-station-details';
export const DELETE_SOW_STATION = '@@sowPQT/delete-station-details';
export const ADD_STATION = '@@sowPQT/add-station';
export const ADD_STATION_SUCCESS = '@@sowPQT/add-station-success';
export const ADD_STATION_FAILURE = '@@sowPQT/add-station-failure';
export const DELETE_STATION_LIST = '@@sowPQT/delete-station-list';
export const DELETE_STATION_LIST_PROCESS = '@@sowPQT/delete-station-list-process';
export const DELETE_STATION_LIST_SUCCESS = '@@sowPQT/delete-station-list-success';
