// src/components/ProjectModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space, Typography, Upload } from 'antd';

import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import { EditFilled, CheckOutlined, CloseOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { addCalibration, updateCalibration } from '../../redux/calibration/action';
import { GetTableConfigutaion } from '../../redux/calibration/saga';
//import { Contractor_List, countries_List, diameters, PipeDiaRate, Project_List, Representative_List, Spread_Config_List, Pipe_Grade_List } from './Database';
const { Option } = Select;

const ProjectModal = (props) => {

    const {

        isModalVisible,
        onClose,
        setIsModalVisible,
        projectDetailForm,
        isEdit,
        setIsEdit,
        editRecord,
        editValue,
        onAddCalibrationaction,
        onUpdateCalibration

    } = props;
    const [newItem, setNewItem] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [reminderList, setReminderList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [frequency, setfrequency] = useState(12);
    const [uniqueLocations, setUniqueLocations] = useState([])
    const [formData, setFormData] = useState({
        // Instrument_Company: '',
        // Identification: '',
        // Code_Model: '',
        // Manufacturer_Company: '',
        // SerialNumber: '',
        // Frequency: '',
        // Status: 'Active',
        // StorageArea: '',
        // CertificateNumber: '',
        // CalibrationDate: '',
        // NextCalibrationDate: '',
        // Expiration: '',
        // Project: '',
        // ProjectNo: '',
        // Reminders: '',
        // Location: '',


        Instrument_Company: 'Acme Instruments',
        Identification: 'Pressure Gauge',
        Code_Model: 'PG-12345',
        Manufacturer_Company: 'Acme Corp',
        SerialNumber: 'SN001',
        Frequency: '12 months',
        StorageArea: 'Warehouse A',
        CertificateNumber: 'CERT-456789',
        CalibrationDate: '2024-12-01',
        NextCalibrationDate: '2025-12-01',
        Expiration: '2026-01-01',
        Project: 'Pipeline Inspection',
        ProjectNo: 'PRJ-7890',
        Reminders: '',
        Location: 'USA',
        
    });

    const LocationList = ['USA', 'India', 'AUSTRALIA']

    useEffect(() => {
        if (isEdit) {
            setFormData(editRecord)
            console.log(editRecord)
            if (editRecord.Reminders)
                setReminderList(editRecord.Reminders.split(','))

        }

        if (uniqueLocations.length == 0)
            fetchData();
    }, []);
    const fetchData = async () => {
        const res = await GetTableConfigutaion('UserManagement_DocControl')
        if (res && res.length > 0) {
            console.log(res)
            // Remove duplicates based on the Location property
            const uniqueLocations = res.filter(
                (item, index, self) =>
                    self.findIndex(obj => obj.Location === item.Location) === index
            )
            setUniqueLocations(uniqueLocations)
        }
    }

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    const onchangechxBox = (isChecked, types) => {
        if (isChecked)
            setReminderList(types)
        else
            setReminderList([])
    }


    const setAdddnewItem = (event) => {
        setNewItem(event.target.value);
    };


    const addItem = async (type, newItem) => {
        // console.log(type, newItem, categoryListdata)
        if (newItem) {

            // const newItem1 = { [tableNames[type]]: newItem }; // Example item
            // addItemToCategoryList(type, newItem1);
            // const obj = {
            //     tableName: type,
            //     data: [{
            //         [tableNames[type]]: newItem
            //     }]
            // };
            setNewItem('')
            // console.log(obj);
            // Uncomment and implement this function
            // await AddOrUpdateMasterData(obj)

            // const category = await getCategoryListFromDatabse();
            // if (category)
            //     setCategoryListdata(category)
            // console.log(category)
        }


    };

    const removeItem = (e) => {
        e.preventDefault();
        setNewItem('');
    }

    const handleInputChange = (name, value) => {

        if (name === 'CalibrationDate' || name == 'NextCalibrationDate' || name === 'Expiration')
            value = moment(value).format('YYYY-MM-DD');
        if (name === 'Frequency')
            value = `${value} months`;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

    };

    const handleCancel = () => {
        projectDetailForm.resetFields();
        setIsEdit(false)

        setFormData({})
        //onClose();
        setIsModalVisible(false)

    };


    const getCategoryValue1 = (type) => {
        if (formData.hasOwnProperty(type)) { // Check if the category exists in selectedOptions
            return formData[type]; // Return the value for the category
        }
    };

    const handleOk = async () => {
        setIsLoading(true)

        //
        // console.log(res)
        if (!isEdit)
            formData['Created_Date'] = moment().format('YYYY-MM-DD').toString()
        formData['Updated_Date'] = moment().format('YYYY-MM-DD').toString()

        if (fileList && fileList.length > 0)
            formData['certificate'] = fileList
        formData['Reminders'] = reminderList.toString()
       // console.log('Project deatils', formData)

        if (isEdit)
            await onUpdateCalibration({ data: formData })
        else
            await onAddCalibrationaction({ data: formData });


        setIsEdit(false)
        setIsLoading(false)
        setIsModalVisible(false)
    }
    const extractNumber = (value) => {
        const match = value.match(/\d+/); // Match the first numeric part of the string
        return match ? parseInt(match[0], 10) : null; // Convert to number or return null if no number found
    };


    return (
        <Modal
            width={1000}
            visible={isModalVisible}
            title="New Calibration"
            className="pipeline-modal trackrecord-modal Tracker-modal"
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary"
                    onClick={async () => await handleOk()}
                    loading = {isLoading}
                >
                    {isEdit ? 'Update' : 'Submit'}
                </Button>,
            ]}
        >
            <Form form={projectDetailForm}
                name="project_form"
                layout="vertical"
                autoComplete="off"
                getcontainer={false}
                //onValuesChange={onValuesChange}
                width={1000}
            >
                <Row gutter={24} >

                    <Col lg={6}>
                        <Form.Item name="Instrument_Company" label="Instrument Company" >
                            <div>
                                <Input onChange={(e) => handleInputChange('Instrument_Company', e.target.value)} size="large"
                                    value={getCategoryValue1('Instrument_Company')} 
                                    placeholder='Enter Instrument Company'
                                    />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="Identification" label="Identification" >
                            <div>
                                <Input onChange={(e) => handleInputChange('Identification', e.target.value)} size="large"
                                    value={getCategoryValue1('Identification')}
                                       placeholder='Enter Identification'
                                    />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="Code_Model" label="Code Model" >
                            <div>
                                <Input onChange={(e) => handleInputChange('Code_Model', e.target.value)} size="large"
                                    value={getCategoryValue1('Code_Model')} 
                                     placeholder='Enter Code Model'
                                    />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="Manufacturer_Company" label="Manufacturer Company" >
                            <div>
                                <Input onChange={(e) => handleInputChange('Manufacturer_Company', e.target.value)} size="large"
                                    value={getCategoryValue1('Manufacturer_Company')} 
                                     placeholder='Manufacturer Company'
                                    />
                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={6}>
                        <Form.Item name="SerialNumber" label="Serial Number" >
                            <div>
                                <Input onChange={(e) => handleInputChange('SerialNumber', e.target.value)} size="large"
                                    value={getCategoryValue1('SerialNumber')} 
                                      placeholder='Enter Serial Number'
                                    />
                            </div>
                        </Form.Item>
                    </Col>



                    <Col lg={6}>
                        <Form.Item name="Status" label="Status" >
                            <div>
                                <Select className='maintenance-select'
                                    value={formData['Status']}
                                    onChange={(value) => handleInputChange('Status', value)} size="large"

                                      placeholder='Select Status'
                                >
                                    <Option value={'Initiated'}>Initiated</Option>
                                    <Option value={'Active'}>Active</Option>
                                    <Option value={'On Hold'}>On Hold</Option>
                                    <Option value={'Completed'}>Completed</Option>
                                    <Option value={'Facility'}>Facility</Option>
                                </Select>
                            </div>

                        </Form.Item>
                    </Col>


                    <Col lg={6}>
                        <Form.Item name="StorageArea" label="Storage Area">
                            <div>
                                <Input onChange={(e) => handleInputChange('StorageArea', e.target.value)} size="large"
                                    value={getCategoryValue1('StorageArea')}
                                     placeholder='Enter Storage Area'
                                    />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="CertificateNumber" label="Certificate Number" >
                            <div>
                                <Input onChange={(e) => handleInputChange('CertificateNumber', e.target.value)} size="large"
                                    value={getCategoryValue1('CertificateNumber')}
                                     placeholder='Enter Certificate Number'
                                    />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <div style={{ marginBottom: '10px', marginTop: '-25px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <Form.Item label="Frequency" name="Frequency">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='months' className='Data360-addafter'
                                    onChange={(e) => handleInputChange('Frequency', Number(e.target.value))}
                                    value={extractNumber(getCategoryValue1('Frequency'))}
                                    style={{ width: '100%', textAlign: 'left' }}
                                    min={0}
                                    type="number"
                                    size="large"
                                     placeholder='Enter Frequency'
                                />

                            </div>

                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="Project" label="Project" >
                            <div>
                                <Input onChange={(e) => handleInputChange('Project', e.target.value)} size="large"
                                    value={getCategoryValue1('Project')} 
                                     placeholder='Enter Project'
                                    />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="ProjectNo" label="Project No." >
                            <div>
                                <Input onChange={(e) => handleInputChange('ProjectNo', e.target.value)} size="large"
                                    value={getCategoryValue1('ProjectNo')} 
                                    placeholder='Enter Project Number'
                                    />
                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={6}>
                        <Form.Item name="Location" label="Location">
                            <div>
                                <Select className='maintenance-select' placeholder={'Select Location'} style={{ marginTop: '0px' }}
                                    onChange={(value) => handleInputChange('Location', value)} size="large"
                                    value={formData['Location'] || null}
                                    showSearch
                                    allowClear
                                  

                                // dropdownRender={menu => (
                                //     <>
                                //         {menu}
                                //         <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                //         <Space align="center" style={{ padding: '0 8px 4px' }}>
                                //             <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                //             <Typography.Link onClick={(e) => addItem('Location', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                //                 <CheckOutlined />
                                //             </Typography.Link>
                                //             <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                //                 <CloseOutlined />
                                //             </Typography.Link>
                                //         </Space>
                                //     </>
                                // )}

                                >
                                    {uniqueLocations &&
                                        uniqueLocations
                                    //.sort((a, b) => a.material.localeCompare(b.ProProject_Nameject)) // Sort by category
                                            .map((item, i) => (
                                                <Option key={i} value={item.Location}> {/* Use item.category as value */}
                                                    {item.Location}
                                                </Option>
                                            ))
                                    }

                                </Select>
                            </div>
                        </Form.Item>
                    </Col>


                    <Col lg={6}>
                        <Form.Item name="CalibrationDate" label="Calibration Date" >
                            <div>
                                <DatePicker
                                    value={formData['CalibrationDate'] ?moment(formData['CalibrationDate']) :''}
                                    onChange={(date) => handleInputChange('CalibrationDate', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>

                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="NextCalibrationDate" label="NextCalibration Date" >
                            <div>
                                <DatePicker
                                    value={formData['NextCalibrationDate'] ?moment(formData['NextCalibrationDate']) :''}
                                    onChange={(date) => handleInputChange('NextCalibrationDate', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>

                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="Expiration" label="Expiration" >
                            <div>
                                <DatePicker
                                    value={formData['Expiration'] ?moment(formData['Expiration']) :''}
                                    onChange={(date) => handleInputChange('Expiration', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>

                        </Form.Item>
                    </Col>

                    <Col lg={24}>
                        <div style={{ marginBottom: '10px', marginTop: '-25px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>

                    <Col lg={12} >
                        <div style={{ paddingTop: '0px' }}>


                            <Checkbox style={{ fontSize: '16px' }} checked={reminderList.length > 0}
                                onChange={(e) => onchangechxBox(e.target.checked, ['90', '60', '30', '15'])}
                            >Reminders </Checkbox>
                            <Checkbox.Group style={{ display: 'block', paddingTop: '5px' }}
                                onChange={(checkedValues) => {
                                    setReminderList(checkedValues)
                                }}
                                value={reminderList}>
                                <Checkbox style={{ marginLeft: '15px' }} value='15'>15 Days</Checkbox>
                                <Checkbox style={{ marginLeft: '15px' }} value='30'>30 Days</Checkbox>
                                <Checkbox style={{ marginLeft: '15px' }} value='60'>60 Days</Checkbox>
                                <Checkbox style={{ marginLeft: '15px' }} value='90'>90 Days</Checkbox>

                            </Checkbox.Group>
                        </div>
                    </Col>

                    <Col lg={24}>
                        <div style={{ marginBottom: '10px', marginTop: '-10px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{ paddingTop: '20px' }}>
                    <Col lg={8}>
                        <Form.Item
                            name="certificate"
                            label="Upload Certificate"
                            required
                        >
                            <Upload {...fileProps} maxCount={1} accept=".doc,.docx,application/pdf" >
                                <Button icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    {isEdit && editRecord.certificate_history && editRecord.certificate_history.length > 0 && (
                        <Form.Item>
                            <ul>
                                {editRecord.certificate_history.map((item, i) =>
                                    item.certificateURL?.fileURL ? (
                                        <li key={i}>
                                            <a href={item.certificateURL.fileURL} target='_blank' rel='noopener noreferrer'>
                                                Certificate Version {item.version}
                                            </a>
                                        </li>
                                    ) : (
                                        <li key={i}>No Certificate Uploaded</li>
                                    )
                                )}
                            </ul>
                        </Form.Item>
                    )}
                </div>
            </Form>
        </Modal>
    );
};

const mapStateToProps = ({ calibrationReducer }) => {
    const isAddUpdateCalibration = get(calibrationReducer, 'isAddUpdateCalibration', false);
    return {
        isAddUpdateCalibration,
    }
}

const mapDispatchToProps = {
    onAddCalibrationaction: addCalibration,
    onUpdateCalibration: updateCalibration,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModal);